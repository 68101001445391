// import React from 'react';
// import { Container, Grid, Box, Typography, TextField, Button, Paper, List, ListItem, ListItemIcon, ListItemText, } from '@mui/material';
// import PhoneIcon from '@mui/icons-material/Phone';
// import EmailIcon from '@mui/icons-material/Email';
// import LocationOnIcon from '@mui/icons-material/LocationOn';
// import AccessTimeIcon from '@mui/icons-material/AccessTime';
// import ReCAPTCHA from 'react-google-recaptcha';
// import  { useState } from 'react';


// const ContactPage = () => {

//   const [captchaValue, setCaptchaValue] = useState(null);


//   const handleCaptchaChange = (value) => {
//     setCaptchaValue(value);
//   };

//     const handleSubmit = (e) => {
//       e.preventDefault();
//       if (!captchaValue) {
//         alert("Please complete the CAPTCHA to submit the form.");
//         return;
//       }
//       // Form submission logic
//     };


//   return (
//     <Container maxWidth="lg" sx={{ my: 6 }}>
//       {/* Page Title */}
//       <Box sx={{ textAlign: 'center', mb: 6 }}>
//         <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#4A4E69' }}>
//           Contact Us
//         </Typography>
//         <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>
//           We’re here to support you. Whether you have a question, need assistance, or want more information, please feel free to reach out.
//         </Typography>
//       </Box>

//       <Grid container spacing={4}>
//         {/* Contact Information */}
//         <Grid item xs={12} md={4}>
//           <Paper elevation={3} sx={{ p: 4, backgroundColor: '#F2E9E4', borderRadius: 2 }}>
//             <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#4A4E69', mb: 2 }}>
//               Get in Touch
//             </Typography>
//             <List>
//               <ListItem>
//                 <ListItemIcon>
//                   <PhoneIcon color="primary" />
//                 </ListItemIcon>
//                 <ListItemText primary="Phone" secondary="(123) 456-7890" />
//               </ListItem>
//               <ListItem>
//                 <ListItemIcon>
//                   <EmailIcon color="primary" />
//                 </ListItemIcon>
//                 <ListItemText primary="Email" secondary="support@informedalf.com" />
//               </ListItem>
//               <ListItem>
//                 <ListItemIcon>
//                   <LocationOnIcon color="primary" />
//                 </ListItemIcon>
//                 <ListItemText primary="Address" secondary="123 Wall Street, New York, US, 10012" />
//               </ListItem>
//               <ListItem>
//                 <ListItemIcon>
//                   <AccessTimeIcon color="primary" />
//                 </ListItemIcon>
//                 <ListItemText primary="Office Hours" secondary="Mon - Fri: 9:00 AM - 6:00 PM" />
//               </ListItem>
//             </List>
//           </Paper>
//         </Grid>

//         {/* Contact Form */}
//         <Grid item xs={12} md={8}>
//           <Paper elevation={3} sx={{ p: 4, backgroundColor: '#E9E4E4', borderRadius: 2 }}>
//             <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#4A4E69', mb: 2 }}>
//               Send Us a Message
//             </Typography>
//             <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
//               Fill out the form below, and a member of our team will respond within 24-48 hours.
//             </Typography>
//             <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2, justifyContent: 'center' }}>
//               <TextField label="Full Name" variant="outlined" fullWidth required />
//               <TextField label="Email Address" variant="outlined" fullWidth required />
//               <TextField label="Phone Number" variant="outlined" fullWidth />
//               <TextField label="Message" variant="outlined" fullWidth multiline rows={4} required />
//               <ReCAPTCHA style={{ alignSelf: 'center' }}
//                 sitekey="6LfMiHwqAAAAAAvG8lKB3ohapum3S2AvAGtYALMR"
//                 onChange={handleCaptchaChange}
//               />
//               <Button onClick={handleSubmit} variant="contained" color="primary" type="submit" sx={{ mt: 1 }}>
//                 Submit
//               </Button>
//             </Box>
//           </Paper>
//         </Grid>
//       </Grid>

//       {/* Additional Contact Options */}
//       <Box sx={{ mt: 8 }}>
//         <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#4A4E69', textAlign: 'center', mb: 4 }}>
//           Additional Ways to Connect
//         </Typography>

//         <Grid container spacing={4} justifyContent="center">
//           {/* Phone Support */}
//           <Grid item xs={12} md={3}>
//             <Paper elevation={3} sx={{ p: 3, backgroundColor: '#C9ADA7', borderRadius: 2, textAlign: 'center' }}>
//               <PhoneIcon fontSize="large" color="secondary" />
//               <Typography variant="h6" sx={{ mt: 2, color: '#4A4E69', fontWeight: 'bold' }}>
//                 Phone Support
//               </Typography>
//               <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
//                 Speak directly with a support specialist for immediate assistance.
//               </Typography>
//             </Paper>
//           </Grid>

//           {/* Email Support */}
//           <Grid item xs={12} md={3}>
//             <Paper elevation={3} sx={{ p: 3, backgroundColor: '#9A8C98', borderRadius: 2, textAlign: 'center' }}>
//               <EmailIcon fontSize="large" color="secondary" />
//               <Typography variant="h6" sx={{ mt: 2, color: '#4A4E69', fontWeight: 'bold' }}>
//                 Email Support
//               </Typography>
//               <Typography variant="body2" color="text.secondary" sx={{ mt: 1, mb: 2.7 }}>
//                 Email us your questions, and we’ll reply within 24-48 hours.
//               </Typography>
//             </Paper>
//           </Grid>

//           {/* Visit Us */}
//           <Grid item xs={12} md={3}>
//             <Paper elevation={3} sx={{ p: 3, backgroundColor: '#4A4E69', borderRadius: 2, textAlign: 'center', color: 'white' }}>
//               <LocationOnIcon fontSize="large" color="inherit" />
//               <Typography variant="h6" sx={{ mt: 2, fontWeight: 'bold' }}>
//                 Visit Our Office
//               </Typography>
//               <Typography variant="body2" sx={{ mt: 1 }}>
//                 Stop by our office during business hours. We’d be happy to meet with you.
//               </Typography>
//             </Paper>
//           </Grid>
//         </Grid>
//       </Box>
      
//     </Container>

    
//   );
// };

// export default ContactPage;
import React, { useState } from 'react';
import { Container, Grid, Box, Typography, TextField, Button, Paper, List, ListItem, ListItemIcon, ListItemText, Snackbar, Alert } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ReCAPTCHA from 'react-google-recaptcha';

const ContactPage = () => {
  const [captchaValue, setCaptchaValue] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!captchaValue) {
      setSnackbarMessage("Please complete the CAPTCHA to submit the form.");
      setSnackbarOpen(true);
      return;
    }

    // Form submission logic here...
    
    // Show success message after form submission
    setSnackbarMessage("We'll get back to you shortly.");
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Container maxWidth="lg" sx={{ my: 6 }}>
      {/* Page Title */}
      <Box sx={{ textAlign: 'center', mb: 6 }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#4A4E69' }}>
          Contact Us
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>
          We’re here to support you. Whether you have a question, need assistance, or want more information, please feel free to reach out.
        </Typography>
      </Box>

      <Grid container spacing={4}>
        {/* Contact Information */}
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ p: 4, backgroundColor: '#F2E9E4', borderRadius: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#4A4E69', mb: 2 }}>
              Get in Touch
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <PhoneIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Phone" secondary="(123) 456-7890" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <EmailIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Email" secondary="support@informedalf.com" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <LocationOnIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Address" secondary="123 Wall Street, New York, US, 10012" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <AccessTimeIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Office Hours" secondary="Mon - Fri: 9:00 AM - 6:00 PM" />
              </ListItem>
            </List>
          </Paper>
        </Grid>

        {/* Contact Form */}
        <Grid item xs={12} md={8}>
          <Paper elevation={3} sx={{ p: 4, backgroundColor: '#E9E4E4', borderRadius: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#4A4E69', mb: 2 }}>
              Send Us a Message
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
              Fill out the form below, and a member of our team will respond within 24-48 hours.
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2, justifyContent: 'center' }}>
              <TextField label="Full Name" variant="outlined" fullWidth required />
              <TextField label="Email Address" variant="outlined" fullWidth required />
              <TextField label="Phone Number" variant="outlined" fullWidth />
              <TextField label="Message" variant="outlined" fullWidth multiline rows={4} required />
              <ReCAPTCHA 
                style={{ alignSelf: 'center' }}
                sitekey="6LfMiHwqAAAAAAvG8lKB3ohapum3S2AvAGtYALMR"
                onChange={handleCaptchaChange}
              />
              <Button type="submit" variant="contained" color="primary" sx={{ mt: 1 }}>
                Submit
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      {/* Additional Contact Options */}
      <Box sx={{ mt: 8 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#4A4E69', textAlign: 'center', mb: 4 }}>
          Additional Ways to Connect
        </Typography>

        <Grid container spacing={4} justifyContent="center">
          {/* Phone Support */}
          <Grid item xs={12} md={3}>
            <Paper elevation={3} sx={{ p: 3, backgroundColor: '#C9ADA7', borderRadius: 2, textAlign: 'center' }}>
              <PhoneIcon fontSize="large" color="secondary" />
              <Typography variant="h6" sx={{ mt: 2, color: '#4A4E69', fontWeight: 'bold' }}>
                Phone Support
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                Speak directly with a support specialist for immediate assistance.
              </Typography>
            </Paper>
          </Grid>

          {/* Email Support */}
          <Grid item xs={12} md={3}>
            <Paper elevation={3} sx={{ p: 3, backgroundColor: '#9A8C98', borderRadius: 2, textAlign: 'center' }}>
              <EmailIcon fontSize="large" color="secondary" />
              <Typography variant="h6" sx={{ mt: 2, color: '#4A4E69', fontWeight: 'bold' }}>
                Email Support
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1, mb: 2.7 }}>
                Email us your questions, and we’ll reply within 24-48 hours.
              </Typography>
            </Paper>
          </Grid>

          {/* Visit Us */}
          <Grid item xs={12} md={3}>
            <Paper elevation={3} sx={{ p: 3, backgroundColor: '#4A4E69', borderRadius: 2, textAlign: 'center', color: 'white' }}>
              <LocationOnIcon fontSize="large" color="inherit" />
              <Typography variant="h6" sx={{ mt: 2, fontWeight: 'bold' }}>
                Visit Our Office
              </Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>
                Stop by our office during business hours. We’d be happy to meet with you.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>

      {/* Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={captchaValue ? 'success' : 'warning'}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ContactPage;
