import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  ListItem,
  ListItemText,
  Button,
  Grid,
  Typography,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  TextField,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from '@mui/material';
import { NavLink, Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import LoginIcon from '@mui/icons-material/Login';
import axios from 'axios';
import Swal from 'sweetalert2';
import image from '../images/logo3.png';
import useMediaQuery from '@mui/material/useMediaQuery';
import ReCAPTCHA from 'react-google-recaptcha';


export default function Navbar() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width: 900px)');
  const [open, openChange] = useState(false);
  const [CurrentRole, setCurrentRole] = useState("");
  const [CurrentName, setCurrentName] = useState("");
  const [CurrentEmail, setCurrentEmail] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [captchaVerified, setCaptchaVerified] = useState(false);


  const handleCaptchaChange = (value) => {
    setCaptchaVerified(!!value); // true if captcha completed
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const functionopenpopup = () => {
    openChange(true);
  };

  const closepopup = () => {
    openChange(false);
  };

  const handleRoleChange = (event) => {
    setCurrentRole(event.target.value);
  };

  const handleNameChange = (event) => {
    setCurrentName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setCurrentEmail(event.target.value);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const submitFormData = async () => {
    if (!captchaVerified) {
      setSnackbarMessage('Please complete the CAPTCHA');
      setSnackbarOpen(true);
      return;
    }
    let data = {
      name: CurrentName,
      email: CurrentEmail,
      current_role: CurrentRole,
      agree: true
    };

    try {
      const res = await axios.post(`${process.env.REACT_APP_URL}/api/enquiries`, data);
      if (res.status === 201) {
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: "Data saved successfully",
          showConfirmButton: false,
          timer: 3000
        });
        closepopup();
        setCaptchaVerified(false); // Reset CAPTCHA status

      } else {
        setSnackbarMessage('Error saving data!');
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage('Error saving data!');
      setSnackbarOpen(true);
    }
  };

  const drawer = (
    <Grid container direction="column" sx={{ padding: 2, bgcolor: "#f8f9fa" }}>
      <Grid item sx={{ marginBottom: 2 }}>
        <ListItem
          button
          component={NavLink}
          to="/about"
          sx={{
            "&:hover": {
              bgcolor: "#e3f2fd",
              borderRadius: "10px",
            },
          }}
        >
          <ListItemText
            to="/"
            primary="About Us"
            sx={{
              fontSize: "18px",
              fontWeight: "600",
              color: "#125782",
              textAlign: "center",
            }}
          />
        </ListItem>
      </Grid>

      <Grid item sx={{ marginBottom: 2 }}>
        <ListItem
          button
          component={NavLink}
          to="/"
          onClick={handleDrawerToggle}
          sx={{
            "&:hover": {
              bgcolor: "#e3f2fd",
              borderRadius: "10px",
            },
          }}
        >
          {/* <ListItemText
            primary="Sample Education"
            sx={{
              fontSize: "18px",
              fontWeight: "600",
              color: "#125782",
              textAlign: "center",
            }}
          /> */}
        </ListItem>
      </Grid>

      <Grid item>
        <Button
          onClick={() => {
            functionopenpopup();
            handleDrawerToggle(); // Close drawer after clicking JOIN NOW
          }}
          sx={{
            width: "100%",
            color: "white",
            bgcolor: "#b31c31",
            padding: "10px 0",
            fontSize: "18px",
            fontWeight: "700",
            borderRadius: "10px",
            "&:hover": {
              bgcolor: "#a01729",
            },
          }}
        >
          JOIN NOW
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <Grid container sx={{ bgcolor: '#ffffff' }}>
      <AppBar component="nav" position="static" elevation={0} sx={{ padding: '16px 0', bgcolor: '#ffffff' }}>
        <Toolbar>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
              <Link href="/" style={{ textDecoration: 'none' }}>
                <img src={image} alt="logo" style={{ width: '58px', height: '59px' }} />
              </Link>
              {/* <Typography sx={{ fontSize: '28px', fontWeight: 'bolder', color: '#125782', padding: '5px 0 0 15px' }}>
                Informed ALF
              </Typography> */}
              <Link to="/" style={{ textDecoration: 'none' }}>
                <Typography style={{ fontSize: '28px', fontWeight: '600', color: '#125782', padding: '5px 0 0 0px' }}>
                  Informed ALF
                </Typography>
              </Link>
            </Grid>
            {isMobile ? (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerToggle}
                sx={{ color: '#125782' }}
              >
                <MenuIcon />
              </IconButton>
            ) : (
              <Grid item sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <NavLink
                  to="/about"
                  style={({ isActive }) => ({
                    textDecoration: 'none',
                    marginRight: '20px',
                    color: isActive ? '#b31c31' : '#125782', // Highlight active link
                    fontWeight: isActive ? 'bolder' : '550',   // Make active link bold
                  })}
                >
                  <Typography sx={{ fontSize: '19px', fontFamily: 'Poppins', fontWeight: '550', }}>
                    About Us
                  </Typography>
                </NavLink>
                <NavLink
                  to="#"
                  style={({ isActive }) => ({
                    textDecoration: 'none',
                    marginRight: '20px',
                    color: isActive ? '#125782' : '#125782', // Highlight active link
                    fontWeight: isActive ? 'bolder' : '550',   // Make active link bold
                  })}
                >
                  {/* <Typography sx={{ fontSize: '19px', fontFamily: 'Poppins', fontWeight: '550', }}>
                    Sample Education
                  </Typography> */}
                </NavLink>
                {/* <NavLink to="#" style={{ textDecoration: 'none', marginRight: '20px' }}>
                  <Typography sx={{ fontSize: '19px', fontWeight: '550', fontFamily: 'Poppins', color: '#125782' }}>
                    Sample Education
                  </Typography>
                </NavLink> */}
                
                <Button onClick={functionopenpopup} sx={{ fontSize: '19px', fontWeight: '600', fontFamily: 'Poppins', color: 'white', bgcolor: '#b31c31', borderRadius: '10px', '&:hover': { bgcolor: '#b31c31', color: 'white' }, marginRight: '20px' }}>
                  JOIN NOW
                </Button>
                <Button href="https://app.dementiawhiz.com" target='_blank' sx={{ fontSize: '19px', fontWeight: '600', fontFamily: 'Poppins', color: 'white', bgcolor: '#a97adb', borderRadius: '10px', '&:hover': { bgcolor: '#a97adb', color: 'white' } }}>
                  Login
                  <IconButton style={{ float: "right", color: "white" }}>
                    <LoginIcon />
                  </IconButton>
                </Button>
              </Grid>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }} // Better open performance on mobile.
        sx={{
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
        }}
      >
        {drawer}
      </Drawer>
      <Dialog
        open={open}
        onClose={closepopup}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          JOIN NOW{" "}
          <IconButton onClick={closepopup} style={{ float: "right" }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2} margin={2}>
            <TextField
              variant="outlined"
              label="Name"
              name="Name"
              value={CurrentName}
              onChange={handleNameChange}
            />
            <TextField
              variant="outlined"
              label="Email"
              name="Email"
              value={CurrentEmail}
              onChange={handleEmailChange}
            />
            <FormControl required sx={{ m: 2, minWidth: 150 }}>
              <InputLabel id="demo-simple-select-required-label">Current-Role </InputLabel>
              <Select
                labelId="demo-simple-select-required-label"
                name="Currentrole"
                id="demo-simple-select-required"
                value={CurrentRole}
                label="CurrentRole"
                onChange={handleRoleChange}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="Caregiver">Caregiver</MenuItem>
                <MenuItem value="Family Member">Family Member</MenuItem>
                <MenuItem value="Patient">Patient</MenuItem>
                <MenuItem value="Assisted Living Facility">Assisted Living Facility</MenuItem>
                <MenuItem value="Activity Taker">Activity Taker</MenuItem>
              </Select>
              <FormHelperText>Required</FormHelperText>
            </FormControl>
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="Agree to terms & conditions"
            />
             <ReCAPTCHA
          sitekey="6LfMiHwqAAAAAAvG8lKB3ohapum3S2AvAGtYALMR" // Replace with your ReCAPTCHA site key
          onChange={handleCaptchaChange}
        />
            <Button
              onClick={submitFormData}
              sx={{
                bgcolor: "#b31c31",
                color: "white",
                '&:hover': {
                  bgcolor: '#a01729',
                },
                fontSize: "18px",
                fontWeight: "700",
                fontFamily: "poppins",
              }}
            >
              Submit
            </Button>
          </Stack>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarMessage.includes('Error') ? 'error' : 'success'}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Grid>
  );
}
