import React from "react";
import { Grid, } from "@mui/material";
import { NavLink, } from "react-router-dom";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
// import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
// import IconButton from "@mui/material/IconButton";
// import Button from "@mui/material/Button";
// import MenuIcon from "@mui/icons-material/Menu";
import  "../style/NavbarStyles.css";
import image from "../images/logo3.png";





export default function Navbar() {


  return ( 
   
         <Grid sx={{bgcolor: "yellow", width: "100%", height: "114.5px", pt: "30px"}} >
          <AppBar component={"nav"} position="static" sx={{ width: "100%", height: "", }}>
            <Toolbar sx={{ bgcolor: "blue", width: "100%", height: "60px", padding: "0px 0px 0px 0px" , margin: "0px 0px 0px 0px"}}>
              <Grid variant="div" sx={{ ml: "5px", mr: "0px" }}>
              <img src={image} alt="logo" style={{width: "58px", height: "59px"}} />
              </Grid>
            <Typography
              color={"#125782"}
              variant="h4"
              component="div"
              sx={{  textAlign: "left",fontWeight: "bold" }}>
              DementiaWhiz
            </Typography>
            <Grid  sx={{ display: { xs: "none", sm: "block" }, ml: "20px", cursor: "pointer", padding: "0px 0px 0px 0px" }}>
              <ul className="navigation-menu" sx={{ fontweight: "700"}} style={{justifyContent: "center", orientation:"underline"}} >
                <li>
                  <NavLink to={"/"}  orientation="underline" sx={{}}>About Us</NavLink>
                </li>

                <li>
                  <NavLink to={"/blog"}>Sample Education</NavLink>
                </li>

                <li>
                  <NavLink to={"/review"}>Take The Quiz</NavLink>
                </li>

                {/* <li>
                  <NavLink to={"/login"}>Log In</NavLink>
                </li> */}
              </ul>
              
            </Grid>
              {/* <Button  variant="contained" 
               sx=
               {{bgcolor:"#125782", 
                height: "59.9px" ,
                width: "130px",
                 ml: "12px",
                  mt: "10px",
                 mb: "5px",
                fontSize: '15px',
                fontWeight: '800',
                fontFamily:"poppins", 
                borderRadius: "4px",
                               }} >
              <big>SIGN Up</big>
              </Button> */}
               
               </Toolbar>

          </AppBar>
        </Grid>


// 





























  )

};














  

// export default Navbar;
