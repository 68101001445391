// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
    font-family: poppins;
    margin: "0px 0px 0px 0px";
}



.navigation-menu{
display: flex;
/* margin-left: 540px; */
/* padding-top: 4px; */
font-size: 18px;
}

.navigation-menu li{
    margin: 0px 23px;
    text-decoration: none;

}

.navigation-menu li a{
    font-style: bold;
   color: #125782;
   text-decoration: none;
   font-weight: 700;

   /* font-size: 25px; */
}

.btn{
    padding-top: 0px;
   
}







`, "",{"version":3,"sources":["webpack://./src/style/NavbarStyles.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,yBAAyB;AAC7B;;;;AAIA;AACA,aAAa;AACb,wBAAwB;AACxB,sBAAsB;AACtB,eAAe;AACf;;AAEA;IACI,gBAAgB;IAChB,qBAAqB;;AAEzB;;AAEA;IACI,gBAAgB;GACjB,cAAc;GACd,qBAAqB;GACrB,gBAAgB;;GAEhB,qBAAqB;AACxB;;AAEA;IACI,gBAAgB;;AAEpB","sourcesContent":["*{\n    font-family: poppins;\n    margin: \"0px 0px 0px 0px\";\n}\n\n\n\n.navigation-menu{\ndisplay: flex;\n/* margin-left: 540px; */\n/* padding-top: 4px; */\nfont-size: 18px;\n}\n\n.navigation-menu li{\n    margin: 0px 23px;\n    text-decoration: none;\n\n}\n\n.navigation-menu li a{\n    font-style: bold;\n   color: #125782;\n   text-decoration: none;\n   font-weight: 700;\n\n   /* font-size: 25px; */\n}\n\n.btn{\n    padding-top: 0px;\n   \n}\n\n\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
