import React from "react";
import { Grid, Typography, Container, Divider, Box, Paper } from "@mui/material";
import { Link } from 'react-router-dom';


function Terms() {
  return (
    <Container maxWidth="lg" sx={{ mt: 8, mb: 8 }}>
      {/* Title Section */}
      <Box sx={{ textAlign: 'center', mb: 6 }}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            color: 'white',
            background: 'linear-gradient(90deg, #4a90e2, #7a53d6)',
            py: 3,
            px: 2,
            borderRadius: 3,
            fontWeight: 'bold',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)',
          }}
        >
          Terms and Conditions
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ fontSize: '1.1rem', mt: 2 }}>
          Please review the terms carefully to understand your rights and responsibilities when using Informed Alf services.
        </Typography>
      </Box>

      {/* Terms and Conditions Sections */}
      <Grid container spacing={4}>
        {termsData.map((section, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            {/* <Paper
              elevation={3}
              sx={{
                p: 4,
                borderRadius: 3,
                bgcolor: index % 2 === 0 ? 'rgba(74,144,226,0.1)' : 'rgba(122,83,214,0.1)',
                boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
                '&:hover': { boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)' },
              }}
            > */}
            <Paper
              elevation={3}
              sx={{
                p: 4,
                borderRadius: 3,
                bgcolor: index % 2 === 0 ? 'rgba(74,144,226,0.1)' : 'rgba(122,83,214,0.1)',
                boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
                minHeight: 200, // Adjust as needed for desired height
                '&:hover': { boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)' },
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                sx={{ color: '#4a90e2', fontWeight: 'bold' }}
              >
                {section.title}
              </Typography>
              <Divider sx={{ mb: 2, backgroundColor: '#e0e7ff' }} />
              <Typography variant="body2" color="text.secondary" sx={{ lineHeight: 1.8 }}>
                {section.content}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>

      {/* Contact Information */}
      <Box sx={{ textAlign: 'center', mt: 6 }}>
        <Typography variant="body1" color="text.secondary" sx={{ fontSize: '1.1rem' }}>
          For any questions, please contact us at:&nbsp;
          <Link href="https://informedalf.com" target="_blank" rel="noopener" sx={{ color: '#7a53d6', fontWeight: 'medium', '&:hover': { color: '#4a90e2' } }}>
            informedalf.com
          </Link>
        </Typography>
      </Box>
    </Container>
  );
}

const termsData = [
  {
    title: '1. Introduction',
    content:
      'Welcome to Informed Alf. By accessing or using our website and services, you agree to comply with and be bound by these Terms and Conditions. Please read them carefully.',
  },
  {
    title: '2. Eligibility',
    content:
      'Our services are for caregivers, healthcare providers, and families of individuals with dementia. By using our services, you confirm that you are at least 18 years old.',
  },
  {
    title: '3. User Responsibilities',
    content:
      'You agree to use our website responsibly, respect patient privacy, and provide accurate information. Unauthorized use outside of dementia care is strictly prohibited.',
  },
  {
    title: '4. Health Information Disclaimer',
    content:
      'Information on our site is not a substitute for professional medical advice. Always consult a qualified healthcare provider with questions about health conditions.',
  },
  {
    title: '5. Privacy Policy',
    content:
      'We respect your privacy. By using our website, you consent to our data collection practices. Review our Privacy Policy for details.',
  },
  {
    title: '6. Third-Party Links',
    content:
      'Our website may contain links to third-party websites for informational purposes. We are not responsible for the content or practices of these websites.',
  },
  {
    title: '7. Limitation of Liability',
    content:
      'In no event shall Informed Alf or its affiliates be liable for any direct, indirect, incidental, or consequential damages from the use of our website.',
  },
  {
    title: '8. Changes to Terms',
    content:
      'We reserve the right to update these Terms and Conditions at any time. Continued use of our services signifies acceptance of the updated terms.',
  },
  {
    title: '9. Account Termination',
    content:
      'We reserve the right to suspend or terminate your account if we believe you have violated these Terms and Conditions or have acted inappropriately.',
  },
  {
    title: '10. Payment and Fees',
    content:
      'Some services on our website may require a fee. You agree to pay all applicable fees, taxes, and charges associated with using our services.',
  },
  {
    title: '11. Governing Law',
    content:
      'These Terms and Conditions are governed by the laws of the jurisdiction in which Informed Alf operates. Any legal disputes will be resolved in the appropriate court in that jurisdiction.',
  },
  {
    title: '12. User-Generated Content',
    content:
      'You are responsible for the content you post on our website. You grant Informed Alf a license to use, display, and share this content as part of our services.',
  },
  {
    title: '13. Security',
    content:
      'We take reasonable measures to protect the security of our website and user data. However, we cannot guarantee complete security of information transmitted online.',
  },
  {
    title: '14. Force Majeure',
    content:
      'Informed Alf shall not be held responsible for failure to perform due to causes beyond our control, including natural disasters, labor strikes, or technical failures.',
  },
  {
    title: '15. Severability',
    content:
      'If any provision of these Terms and Conditions is deemed invalid or unenforceable, the remainder of the terms will remain in full force and effect.',
  },
];


export default Terms;
