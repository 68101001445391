import React from "react";
import Header from "../components/Header";
import {
  Grid,
  Typography,
  Button,
  Link,
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  DialogTitle,
} from "@mui/material";
import Divider from "@mui/material/Divider";

// import images
import about1 from "../images/about_page_banner.webp";
import about_card1 from "../images/about_card1.png";
import about_circle from "../images/about_circle1.webp";
import cont5 from "../images/5thcont.webp";
import thenewyorktimes from "../images/thenewyorktimes.avif";
import usatoday from "../images/usatoday.avif";
import forbes from "../images/forbes.avif";
import daydrink from "../images/day-drink.jpg";
import mobile from "../images/mobile2.png";
import image from "../images/logo3.png";
import sun from "../images/sun.png";
import { useState } from "react";


const About = () => {

  const [openDialog, setOpenDialog] = useState(false);


  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  // Function to handle closing the modal
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const teamMembers = [
    {
      name: "Nick Allen",
      position: "CEO & Founder",
      image: "https://images.prismic.io/sunnysidetest/ff740c00-6b69-4916-a36d-e5ee41eb7c24_01-Nick-Allen.png?auto=compress,format?w=384&q=100",
    },
    {
      name: "Ian Andersen",
      position: "Co-Founder & CGO",
      image: "https://images.prismic.io/sunnysidetest/fa77fe0a-63f2-4fbe-9b0c-3199dfb980ab_02-Ian-Andersen.png?auto=compress,format?w=384&q=100",
    },
    {
      name: "Jane Smith",
      position: "Head of Customer Experience",
      image: "https://images.prismic.io/sunnysidetest/ff740c00-6b69-4916-a36d-e5ee41eb7c24_01-Nick-Allen.png?auto=compress,format?w=384&q=100",
    },
    {
      name: "Jane Smith",
      position: "Chief Product and Technology Officer",
      image: "https://images.prismic.io/sunnysidetest/ff740c00-6b69-4916-a36d-e5ee41eb7c24_01-Nick-Allen.png?auto=compress,format?w=384&q=100",
    }, {
      name: "Jane Smith",
      position: "Software Engineer",
      image: "https://images.prismic.io/sunnysidetest/ff740c00-6b69-4916-a36d-e5ee41eb7c24_01-Nick-Allen.png?auto=compress,format?w=384&q=100",
    }, {
      name: "Jane Smith",
      position: "Customer Experience Manager",
      image: "https://images.prismic.io/sunnysidetest/ff740c00-6b69-4916-a36d-e5ee41eb7c24_01-Nick-Allen.png?auto=compress,format?w=384&q=100",
    }, {
      name: "Jane Smith",
      position: "Software Engineer",
      image: "https://images.prismic.io/sunnysidetest/ff740c00-6b69-4916-a36d-e5ee41eb7c24_01-Nick-Allen.png?auto=compress,format?w=384&q=100",
    }, {
      name: "Jane Smith",
      position: "Software Engineer",
      image: "https://images.prismic.io/sunnysidetest/ff740c00-6b69-4916-a36d-e5ee41eb7c24_01-Nick-Allen.png?auto=compress,format?w=384&q=100",
    }, {
      name: "Jane Smith",
      position: "Customer Experience Manager",
      image: "https://images.prismic.io/sunnysidetest/ff740c00-6b69-4916-a36d-e5ee41eb7c24_01-Nick-Allen.png?auto=compress,format?w=384&q=100",
    }, {
      name: "Jane Smith",
      position: "Senior Product Manager",
      image: "https://images.prismic.io/sunnysidetest/ff740c00-6b69-4916-a36d-e5ee41eb7c24_01-Nick-Allen.png?auto=compress,format?w=384&q=100",
    }, {
      name: "Jane Smith",
      position: "Customer Experience Manager",
      image: "https://images.prismic.io/sunnysidetest/ff740c00-6b69-4916-a36d-e5ee41eb7c24_01-Nick-Allen.png?auto=compress,format?w=384&q=100",
    }, {
      name: "Jane Smith",
      position: "Head of Social Media & Influencer Partnerships",
      image: "https://images.prismic.io/sunnysidetest/ff740c00-6b69-4916-a36d-e5ee41eb7c24_01-Nick-Allen.png?auto=compress,format?w=384&q=100",
    }, {
      name: "Jane Smith",
      position: "Podcast Host & Content Specialist",
      image: "https://images.prismic.io/sunnysidetest/ff740c00-6b69-4916-a36d-e5ee41eb7c24_01-Nick-Allen.png?auto=compress,format?w=384&q=100",
    }, {
      name: "Jane Smith",
      position: "Social Media Coordinator",
      image: "https://images.prismic.io/sunnysidetest/ff740c00-6b69-4916-a36d-e5ee41eb7c24_01-Nick-Allen.png?auto=compress,format?w=384&q=100",
    }, {
      name: "Jane Smith",
      position: "Head of Lifecycle",
      image: "https://images.prismic.io/sunnysidetest/ff740c00-6b69-4916-a36d-e5ee41eb7c24_01-Nick-Allen.png?auto=compress,format?w=384&q=100",
    }, {
      name: "Jane Smith",
      position: "Software Engineer",
      image: "https://images.prismic.io/sunnysidetest/ff740c00-6b69-4916-a36d-e5ee41eb7c24_01-Nick-Allen.png?auto=compress,format?w=384&q=100",
    },
    // Add more members as needed
  ];

  return (
    <>
      <Header />

      <Grid
        container
        spacing={2}
        sx={{
          xs: {
            justifyContent: "center",
            alignItems: "center",
          },
          padding: {
            xs: "0px",
            sm: "0px",
            md: "0px 88px 80px 88px",
            lg: "0px 88px 80px 88px",
          },
        }}
      >
        {/* Text Section */}
        <Grid
          item
          xs={12}
          sm={9}
          md={5.8}
          lg={5}
          order={{ xs: 2, lg: 1, md: 1 }} // On small screens, text will appear below image
          sx={{
            justifyContent: "center",
            alignItems: "center",
            margin: {
              xs: "10px 0px 0px 25px", // Extra small screens
              sm: "0px 0px 0px 90px", // Small screens
              md: "120px 0px 0px 0px", // Medium screens
              lg: "0px", // Large screens
              xl: "0px", // Extra large screens
            },
          }}
        >
          <Typography
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              padding: {
                xs: "0px 0px 10px 0px",
                md: "0px 0px 10px 20px",
                sm: "0px 0px 30px 10px",
                lg: "150px 0px 0px 0px",
              },
              fontSize: {
                xs: "24px",
                sm: "44px",
                md: "52px",
                lg: "64px",
              },
              fontWeight: "600",
              fontFamily: "Recoleta medium",
              color: "#124A2C",
              textAlign: "left",
              lineHeight: {
                xs: "32px",
                sm: "47px",
                md: "52px",
                lg: "64px",
              },
            }}
          >
            "Memories may fade, but love and kindness endure forever."
          </Typography>
        </Grid>

        {/* Image Section */}
        <Grid
          item
          xs={10}
          md={6.2}
          sm={11.5}
          lg={7}
          display="flex"
          justifyContent="center"
          order={{ xs: 1, lg: 2 }} // On small screens, image will appear above text
          sx={{
            margin: {
              xs: "10px 0px 0px 25px", // Extra small screens
              sm: "0px 0px 0px 20px", // Small screens
              md: "0px 0px 0px 0px", // Medium screens
              lg: "0px", // Large screens
              xl: "0px", // Extra large screens
            },
          }}
        >
          <Box
            component="img"
            src={about1}
            alt="logo"
            sx={{
              width: "100%",
              maxWidth: { xs: "100%", md: "760px", lg: "760px" }, // limits width for larger screens
              height: "auto", // keeps aspect ratio
            }}
          />
        </Grid>
      </Grid>



      {/* 2nd container */}
      {/* 2nd container */}
      {/* 2nd container */}
      {/* 2nd container */}
      {/* 2nd container */}

      <Grid
        container
        sx={{
          bgcolor: "#FBF7E9",
          padding: {
            xs: "10px", // Smaller padding for mobile
            sm: "30px 10px 10px 10px",
            md: "20px",
            lg: "80px 88px",
            xl: "100px 120px",
          },
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={8} md={6} lg={4} sm={6} sx={{ display: "flex", justifyContent: "center" }}>
          <img
            src={sun}
            alt="logo"
            style={{
              width: "100%",
              maxWidth: "53px",
              height: "auto",
            }}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "center" }} >
          <Typography
            sx={{
              textAlign: "center",
              padding: {
                xs: "10px 0",
                lg: "20px 0px 0px 20px",
              },
              fontSize: {
                xs: "24px",
                lg: "48px",
                md: "48px",
                sm: "48px",
              },
              fontWeight: "600",
              fontFamily: "Recoleta medium",
              color: "#124A2C",
            }}
          >
            What we stand for
          </Typography>
        </Grid>


        <Grid
          item
          xs={12}
          md={12}
          lg={9}
          sx={{
            justifyContent: "center",
            alighnItems: "center",
          }}
        ><Grid item xs={12} md={12} lg={12} sx={{ padding: "20px", textAlign: "center" }}>
            <Typography
              sx={{
                padding: {
                  xs: "0px",
                  lg: "10px 0 40px 20px",
                },
                fontSize: {
                  xs: "16px",
                  sm: "30px",
                  md: "22px",
                  lg: "25px",
                },
                fontWeight: "300",
                fontFamily: "Recoleta medium",
                color: "#000000",
                lineHeight: {
                  xs: "24px",
                  lg: "35px",
                  md: "30px",
                  sm: "40px",
                },
              }}
            >
              At Informed ALF, we are deeply committed to providing compassionate, dignified, and personalized care for those living with dementia. Our mission is to create a safe and nurturing environment where residents feel understood, valued, and at peace. We believe that every individual deserves respect and meaningful engagement, regardless of memory loss.
            </Typography>
          </Grid>
        </Grid>

        {/* cards starting */}

        <Grid
          container
          spacing={2}
          sx={{
            bgcolor: "#FBF7E9",
            padding: {
              xs: "16px",
              sm: "16px",
              md: "32px",
              lg: "64px 48px",
              xl: "80px 88px",
            },
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/** Card 1 */}
          <Grid
            item
            xs={11}
            // sm={8}
            md={4}
            sx={{
              display: "flex",

              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              padding: { xs: "16px 16px 32px 16px", lg: "16px 48px 64px 48px" },
              margin: { xs: "0px 0px 0px 20px", sm: "0px 0px 0px 0px", lg: "0px 0px 0px 0px" },
            }}
          >
            <img
              src={about_card1}
              alt="Empathy & Understanding"
              style={{
                width: "100%",
                maxWidth: "291px",
                height: "auto",
              }}
            />
            <Typography
              variant="h6"
              sx={{
                mt: 2,
                fontWeight: "600",
                fontFamily: "Recoleta medium",
                color: "#124A2C",
                fontSize: { xs: "25px", lg: "28px" },
              }}
            >
              Empathy & Understanding
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "19px", lg: "21px" },
                fontWeight: 400,
                fontFamily: "Recoleta medium",
                color: "#000000",
                textAlign: "center",
                lineHeight: { sm: "29px" },
                mt: 1,
              }}
            >
              We treat every resident with patience, empathy, and a genuine desire to understand their unique journey.
            </Typography>
          </Grid>

          {/** Card 2 */}
          <Grid
            item
            xs={11}
            // sm={6}
            md={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              padding: { xs: "16px", lg: "16px 48px 64px 48px" },
              margin: { xs: "0px 0px 20px 20px", sm: "0px 0px 0px 0px", lg: "0px 0px 0px 0px" },

            }}
          >
            <img
              src={about_card1}
              alt="Family Support"
              style={{
                width: "100%",
                maxWidth: "291px",
                height: "auto",
              }}
            />
            <Typography
              variant="h6"
              sx={{
                mt: 2,
                fontWeight: "600",
                fontFamily: "Recoleta medium",
                color: "#124A2C",
                fontSize: { xs: "25px", lg: "28px" },

              }}
            >
              Family Support
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "19px", lg: "21px" },
                fontWeight: 400,
                fontFamily: "Recoleta medium",
                color: "#000000",
                textAlign: "center",
                lineHeight: { sm: "29px" },
                mt: 1,
              }}
            >
              We value family involvement and strive to create a partnership with families, offering resources and support to ease their journey alongside their loved ones.
            </Typography>
          </Grid>

          {/** Card 3 */}
          <Grid
            item
            xs={12}
            // sm={6}
            md={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              padding: { xs: "16px", lg: "16px 48px 64px 48px" },
              margin: { xs: "0px 0px 0px 20px", sm: "0px 0px 0px 0px", lg: "0px 0px 0px 0px" },

            }}
          >
            <img
              src={about_card1}
              alt="Dignity in Care"
              style={{
                width: "100%",
                maxWidth: "291px",
                height: "auto",
              }}
            />
            <Typography
              variant="h6"
              sx={{
                mt: 2,
                fontWeight: "600",
                fontFamily: "Recoleta medium",
                color: "#124A2C",
                fontSize: { xs: "25px", lg: "28px" },
              }}
            >
              Dignity in Care
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "19px", lg: "21px" },
                fontWeight: 400,
                fontFamily: "Recoleta medium",
                color: "#000000",
                textAlign: "center",
                lineHeight: "29px",
                mt: 1,
              }}
            >
              We approach care with the utmost respect, ensuring that each resident is treated with dignity, allowing them to retain a sense of self-worth and purpose.
            </Typography>
          </Grid>
        </Grid>


        {/* card4 */}
        <Grid container spacing={2} sx={{ bgcolor: "#FBF7E9", padding: "16px" }}>
          {/* Card 1 */}
          <Grid item xs={12} md={4} sx={{ padding: { xs: "8px", lg: "16px 48px 64px 48px" } }}>
            <Grid container direction="column" alignItems="center">
              <Grid item xs={12}
                sx={{

                  margin: { xs: "0px 0px 0px 20px", sm: "0px 0px 0px 0px", lg: "0px 0px 0px 0px" },

                }}
              >
                <img
                  src={about_card1}
                  alt="logo"
                  style={{
                    width: "100%",
                    maxWidth: "291px",
                    height: "138px",
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: { xs: "25px", lg: "28px" },
                    fontWeight: 600,
                    fontFamily: "Recoleta medium",
                    color: "#124A2C",
                    textAlign: "center",
                    lineHeight: { xs: "28px", lg: "30px" },
                    mt: 2,
                  }}
                >
                  Safety & Comfort
                </Typography>
              </Grid>
              <Grid item xs={11.5}>
                <Typography
                  sx={{
                    fontSize: { xs: "19px", lg: "21px" },
                    fontWeight: 400,
                    fontFamily: "Recoleta medium",
                    color: "#000000",
                    textAlign: "center",
                    lineHeight: "29px",
                    mt: 1,
                  }}
                >
                  Our facility is designed with the highest standards of safety and comfort in mind, using dementia-friendly layouts to reduce anxiety and enhance well-being.
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {/* Card 2 */}
          <Grid item xs={12} md={4} sx={{ padding: { xs: "8px", lg: "16px 48px 64px 48px" } }}>
            <Grid container direction="column" alignItems="center">
              <Grid item xs={12}
                sx={{
                  margin: { xs: "23px 0px 0px 20px", sm: "0px 0px 0px 0px", lg: "0px 0px 0px 0px" },

                }}>
                <img
                  src={about_card1}
                  alt="logo"
                  style={{
                    width: "100%",
                    maxWidth: "291px",
                    height: "138px",
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: { xs: "25px", lg: "28px" },
                    fontWeight: 600,
                    fontFamily: "Recoleta medium",
                    color: "#124A2C",
                    textAlign: "center",
                    lineHeight: { xs: "29px", lg: "30px" },
                    mt: 2,
                  }}
                >
                  Our Mission & Vision
                </Typography>
              </Grid>
              <Grid item xs={11.5}>
                <Typography
                  sx={{
                    fontSize: { xs: "19px", lg: "21px" },
                    fontWeight: 400,
                    fontFamily: "Recoleta medium",
                    color: "#000000",
                    textAlign: "center",
                    lineHeight: "29px",
                    mt: 1,
                  }}
                >
                  Our mission is to provide compassionate, respectful, and person-centered care that enhances the quality of life for individuals living with dementia.
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {/* Card 3 */}
          <Grid item xs={12} md={4} sx={{ padding: { xs: "8px", lg: "16px 48px 64px 48px" } }}>
            <Grid container direction="column" alignItems="center">
              <Grid item xs={11} sx={{
                margin: { xs: "23px 0px 0px 20px", sm: "0px 0px 0px 0px", lg: "0px 0px 0px 0px" },

              }}>
                <img
                  src={about_card1}
                  alt="logo"
                  style={{
                    width: "100%",
                    maxWidth: "291px",
                    height: "138px",
                  }}
                />
              </Grid>
              <Grid item xs={11}>
                <Typography
                  sx={{
                    fontSize: { xs: "25px", lg: "28px" },
                    fontWeight: 600,
                    fontFamily: "Recoleta medium",
                    color: "#124A2C",
                    textAlign: "center",
                    lineHeight: { xs: "28px", lg: "30px" },
                    mt: 2,
                  }}
                >
                  Volunteer & Career Opportunities
                </Typography>
              </Grid>
              <Grid item xs={11.5}>
                <Typography
                  sx={{
                    fontSize: { xs: "19px", lg: "21px" },
                    fontWeight: 400,
                    fontFamily: "Recoleta medium",
                    color: "#000000",
                    textAlign: "center",
                    lineHeight: "29px",
                    mt: 1,
                  }}
                >
                  We welcome compassionate individuals to join our community as volunteers or team members. Explore our opportunities and become part of a rewarding mission.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Grid>

      {/* cards end */}

      {/* 3rd container */}

      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        sx={{
          justifyContent: "center",
          alignItems: "center", // Fix 'alignItems' typo

          padding: {
            xs: "0px",
            sm: "0px",
            md: "0px",
            lg: "80px 88px",
            xl: "0px",
          },
        }}
      >
        <Grid
          item
          xs={9}
          sm={12}
          md={12}
          lg={8}
          sx={{
            justifyContent: "center",
            alignItems: "center", // Fix 'alignItems' typo
          }}
        >

          <Typography
            sx={{
              padding: {
                xs: "30px 0px 35px 0px",
                sm: "24px 0px 10px 0px",
                md: "24px 0px 30px 0px",
                lg: "24px 0px 0px 0px",
                xl: "24px 0px 0px 0px",
              },
              fontSize: {
                xs: "24px",
                sm: "44px",
                md: "24px",
                lg: "48px",
                xl: "24px",
              },
              fontWeight: "500",
              fontFamily: "Recoleta medium",
              color: "#124A2C",
              textAlign: "center",
              lineHeight: {
                xs: "30px",
                sm: "45px",
                md: "20px",
                lg: "45px",
                xl: "20px",
              },
            }}
          >
            Our patients' average experience within the first 30 days
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={10}
          md={12}
          lg={12}
          sx={{
            padding: {
              xs: "0px",
              sm: "0px",
              md: "0px",
              lg: "48px 0px 0px 0px",
              xl: "0px",
            },
            justifyContent: "space-evenly",
            display: "flex",
            flexWrap: "wrap", // Added to handle overflow and make it responsive
          }}
        >
          {/** Repeat this pattern for each Grid item */}
          <Grid
            item
            xs={10}
            md={12}
            lg={2}
            sx={{
              padding: {
                xs: "10px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "40px 0px 0px 0px",
                xl: "0px 0px 0px 0px",
              },

            }}
          >
            <Typography
              sx={{
                margin: {
                  xs: "0px 0px 15px 10px",
                  sm: "40px 0px 10px 0px",
                  md: "0px 0px 30px 0px",
                  lg: "0px 0px 0px 0px",
                  xl: "0px 0px 0px 0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "64px",
                  xl: "24px",
                },
                fontWeight: "400",
                fontFamily: "Recoleta medium",
                color: "#124A2C",
                textAlign: "center",
                lineHeight: {
                  xs: "0px",
                  sm: "0px",
                  md: "0px",
                  lg: "45px",
                  xl: "0px",
                },
              }}
            >
              49%
            </Typography>
            <Typography
              sx={{
                padding: {
                  xs: "10px 0px 30px 0px",
                  sm: "10px 0px 30px 0px",
                  md: "10px 0px 30px 0px",
                  lg: "20px 0px 0px 0px",
                  xl: "10px 0px 0px 0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "16px",
                  xl: "24px",
                },
                fontWeight: "700",
                // fontFamily: "Recoleta medium",
                color: "#828282",
                textAlign: "center",
                lineHeight: {
                  xs: "30px",
                  sm: "30px",
                  md: "0px",
                  lg: "20px",
                  xl: "0px",
                },
              }}
            >
              PHYSICAL HEALTH & MOBILITY
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            md={12}
            lg={1}
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              margin: {
                xs: "0px 0px 30px 0px",
                sm: "0px 0px 30px 0px",
                md: "0px 0px 30px 0px",
                // lg: "0px 0px 0px 0px",
                // xl: "0px 0px 0px 0px",
              },
            }}
          >
            <img
              src={sun}
              alt="logo"
              style={{
                width: "100%",
                maxWidth: "52px",
                height: "auto",
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={12}
            lg={2}
            sx={{
              justifyContent: "center",
              alignItems: "center", // Fix 'alignItems' typo
              padding: {
                xs: "0px",
                sm: "0px",
                md: "0px",
                lg: "40px",
                xl: "0px",
              },
            }}
          >
            <Typography
              sx={{

                margin: {
                  xs: "0px 0px 30px 10px",
                  sm: "0px 0px 20px 0px",
                  md: "0px 0px 30px 0px",
                  // lg: "0px 0px 0px 0px",
                  // xl: "0px 0px 0px 0px",
                },

                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "64px",
                  xl: "24px",
                },
                fontWeight: "400",
                fontFamily: "Recoleta medium",
                color: "#124A2C",
                textAlign: "center",
                lineHeight: {
                  xs: "0px",
                  sm: "0px",
                  md: "0px",
                  lg: "45px",
                  xl: "0px",
                },
              }}
            >
              80%
            </Typography>
            <Typography
              sx={{
                margin: {
                  xs: "0px 0px 30px 0px",
                  sm: "0px 0px 30px 0px",
                  md: "0px 0px 30px 0px",
                  // lg: "0px 0px 0px 0px",
                  // xl: "0px 0px 0px 0px",
                },
                padding: {
                  xs: "10px",
                  sm: "10px",
                  md: "10px",
                  lg: "19px 10px 0px 0px",
                  xl: "10px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "22px",
                  xl: "24px",
                },
                fontWeight: "750",
                // fontFamily: "Recoleta medium",
                color: "#828282",
                textAlign: "center",
                lineHeight: {
                  xs: "0px",
                  sm: "0px",
                  md: "0px",
                  lg: "20px",
                  xl: "0px",
                },
              }}
            >
              Sleep Quality
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            md={12}
            lg={1}
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              margin: {
                xs: "0px 0px 30px 0px",
                sm: "0px 0px 30px 0px",
                md: "0px 0px 30px 0px",
                // lg: "0px 0px 0px 0px",
                // xl: "0px 0px 0px 0px",
              },
            }}
          >
            <img
              src={sun}
              alt="logo"
              style={{
                width: "100%",
                maxWidth: "52px",
                height: "auto",
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={2}
            sx={{
              padding: {
                xs: "0px",
                sm: "0px",
                md: "0px",
                lg: "40px",
                xl: "0px",
              },
            }}
          >
            <Typography
              sx={{
                margin: {
                  xs: "0px 0px 30px 10px",
                  sm: "0px 0px 10px 0px",
                  md: "0px 0px 30px 0px",
                  // lg: "0px 0px 0px 0px",
                  // xl: "0px 0px 0px 0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "64px",
                  xl: "24px",
                },
                fontWeight: "400",
                fontFamily: "Recoleta medium",
                color: "#124A2C",
                textAlign: "center",
                lineHeight: {
                  xs: "0px",
                  sm: "0px",
                  md: "0px",
                  lg: "45px",
                  xl: "0px",
                },
              }}
            >
              65%
            </Typography>
            <Typography
              sx={{
                margin: {
                  xs: "0px 0px 30px 0px",
                  sm: "0px 0px 40px 0px",
                  md: "0px 0px 40px 0px",
                  // lg: "0px 0px 0px 0px",
                  // xl: "0px 0px 0px 0px",
                },
                padding: {
                  xs: "10px",
                  sm: "10px",
                  md: "10px",
                  lg: "10px 10px 0px 0px",
                  xl: "10px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "14px",
                  xl: "24px",
                },
                fontWeight: "750",
                // fontFamily: "Recoleta medium",
                color: "#828282",
                textAlign: "center",
                lineHeight: {
                  xs: "30px",
                  sm: "30px",
                  md: "0px",
                  lg: "17px",
                  xl: "0px",
                },
              }}
            >
              COMMUNICATION AND EXPRESSION
            </Typography>
          </Grid>
        </Grid>
      </Grid>


      {/* 4th container */}

      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          bgcolor: "#FBF7E9",
          padding: {
            xs: "20px",  // Adjust padding for smaller screens
            sm: "20px",
            md: "40px",  // Adjust padding for medium screens
            lg: "80px 88px 80px 88px",
            xl: "100px",
          },
        }}
      >
        <Grid item xs={3} sm={3} md={12} lg={12}
          sx={{
            margin: {
              xs: "0px 0px 0px 15px",
              sm: "0px 0px 0px 0px",
              md: "0px 0px 0px 0px",
              // lg: "0px 0px 0px 0px",
              // xl: "0px 0px 0px 0px",
            },
          }}>
          <img
            src={sun}
            alt="logo"
            style={{
              width: "100%", // Make image responsive
              maxWidth: "52px",
              height: "auto",
            }}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <Typography
            sx={{
              padding: {
                xs: "10px 0",
                sm: "10px 0",
                md: "15px 0",
                lg: "1px 0px 10px 0px",
                xl: "10px 0",
              },
              fontSize: {
                xs: "24px",
                sm: "34px",
                md: "24px",
                lg: "48px",
                xl: "24px",
              },
              fontWeight: "600",
              fontFamily: "Recoleta medium",
              color: "#124A2C",
              textAlign: "center", // Adjust text alignment
            }}
          >
            The Science
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={7.3}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            padding: {
              xs: "0px 10px",  // Padding for smaller screens
              sm: "0px 10px",
              md: "0px",
              lg: "0px",
              xl: "0px",
            },
          }}
        >
          <Typography
            sx={{
              justifyContent: "center",
              alignItems: "center",
              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 60px 0px",
                md: "0px 0px 40px 0px",
                lg: "1px 0px 40px 0px",
                xl: "0px",
              },
              fontSize: {
                xs: "16px",  // Adjust for small screens
                sm: "32px",
                md: "18px",
                lg: "25px",
                xl: "20px",
              },
              fontWeight: "200",
              fontFamily: "Recoleta medium",
              color: "#000000",
              textAlign: "center",
              lineHeight: {
                xs: "1.2",
                sm: "1.2",
                md: "1.4",
                lg: "35px",
                xl: "1.5",
              },
            }}
          >
            Studies have shown that person-centered care can improve outcomes by enhancing engagement, reducing behavioral symptoms, and increasing residents' overall quality of life.
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{
            display: {
              xs: "block",  // For small devices (xs), use block instead of flex
              sm: "block",  // For small screens (sm), use block as well
              md: "flex",   // For medium screens (md) and above, use flex
              lg: "flex",   // For large screens (lg) and above, use flex
            },
            bgcolor: "#FBF7E9",
            padding: {
              xs: "30px 0",
              sm: "10px 0",
              md: "0px",
              lg: "16px 16px 16px 16px",
              xl: "0px",
            },
          }}
        >
          {/* yellow card 3 */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={4}
            sx={{
              bgcolor: "#FBF7E9",
              padding: {
                xs: "10px",
                sm: "0px 0px 0px 0px",
                md: "10px",
                lg: "16px",
                xl: "0px",
              },
            }}
          >
            <img
              src={about_circle}
              alt="logo"
              style={{
                width: "100%", // Make image responsive
                maxWidth: "138px",
                height: "138px",
                alignItems: "left",
              }}
            />
            <Typography
              sx={{
                justifyContent: "center",
                alignItems: "center",
                padding: {
                  xs: "10px 0",
                  sm: "10px 0",
                  md: "15px 0",
                  lg: "4px 0px 16px 0px",
                  xl: "0px",
                },
                fontSize: {
                  xs: "20px",
                  sm: "32px",
                  md: "24px",
                  lg: "28px",
                  xl: "24px",
                },
                fontWeight: "600",
                fontFamily: "Recoleta medium",
                color: "#124A2C",
                textAlign: "center",
              }}
            >
              Family Involvement
            </Typography>

            <Typography
              sx={{
                fontSize: {
                  xs: "16px",
                  sm: "28px",
                  md: "20px",
                  lg: "21px",
                  xl: "24px",
                },
                fontWeight: "400",
                fontFamily: "Recoleta medium",
                color: "#000000",
                textAlign: "center",
                lineHeight: {
                  xs: "1.4",
                  sm: "1.4",
                  md: "1.4",
                  lg: "25px",
                  xl: "1.5",
                },
              }}
            >
              Family members are an essential part of the care team, and research shows that their involvement positively impacts the quality of care and overall outcomes.
            </Typography>
          </Grid>

          {/* yellow card 2 */}
          <Grid
            item
            xs={12}
            md={12}
            lg={4}
            sx={{
              bgcolor: "#FBF7E9",
              padding: {
                xs: "10px",
                sm: "80px 0px 0px 0px",
                md: "10px",
                lg: "16px",
                xl: "0px",
              },
            }}
          >
            <img
              src="https://www.sunnyside.co/_next/image?url=%2F_next%2Fstatic%2Fimage%2Fpublic%2Fimg%2Fsunnyside%2Fsun_step_2.9cf15bb7871fbf4e40de3f13d9996050.png&w=256&q=75"
              alt="logo"
              style={{
                width: "100%", // Make image responsive
                maxWidth: "138px",
                height: "138px",
                alignItems: "left",
              }}
            />
            <Typography
              sx={{
                justifyContent: "center",
                alignItems: "center",
                padding: {
                  xs: "10px 0",
                  sm: "10px 0",
                  md: "15px 0",
                  lg: "4px 0px 16px 0px",
                  xl: "0px",
                },
                fontSize: {
                  xs: "20px",
                  sm: "32px",
                  md: "24px",
                  lg: "28px",
                  xl: "24px",
                },
                fontWeight: "600",
                fontFamily: "Recoleta medium",
                color: "#124A2C",
                textAlign: "center",
              }}
            >
              Music Therapy and Art Therapy
            </Typography>

            <Typography
              sx={{
                fontSize: {
                  xs: "16px",
                  sm: "28px",
                  md: "20px",
                  lg: "21px",
                  xl: "24px",
                },
                fontWeight: "400",
                fontFamily: "Recoleta medium",
                color: "#000000",
                textAlign: "center",
                lineHeight: {
                  xs: "1.4",
                  sm: "1.4",
                  md: "1.4",
                  lg: "25px",
                  xl: "1.5",
                },
              }}
            >
              Music and art therapy have gained attention as effective therapies for individuals with dementia, helping to improve quality of life and emotional health.
            </Typography>
          </Grid>

          {/* yellow card 3 */}
          <Grid
            item
            xs={12}
            md={12}
            lg={4}
            sx={{
              bgcolor: "#FBF7E9",
              padding: {
                xs: "10px",
                sm: "80px 0px 60px 0px",
                md: "10px",
                lg: "16px",
                xl: "0px",
              },
            }}
          >
            <img
              src="https://www.sunnyside.co/_next/image?url=%2F_next%2Fstatic%2Fimage%2Fpublic%2Fimg%2Fsunnyside%2Fsun_step_3.dc56b9b02ab7e67531428e9c5d448793.png&w=256&q=75"
              alt="logo"
              style={{
                width: "100%", // Make image responsive
                maxWidth: "138px",
                height: "138px",
                alignItems: "left",
              }}
            />
            <Typography
              sx={{
                justifyContent: "center",
                alignItems: "center",
                padding: {
                  xs: "10px 0",
                  sm: "10px 0",
                  md: "15px 0",
                  lg: "4px 0px 16px 0px",
                  xl: "0px",
                },
                fontSize: {
                  xs: "20px",
                  sm: "32px",
                  md: "24px",
                  lg: "28px",
                  xl: "24px",
                },
                fontWeight: "600",
                fontFamily: "Recoleta medium",
                color: "#124A2C",
                textAlign: "center",
              }}
            >
              Tailored Activities
            </Typography>

            <Typography
              sx={{
                fontSize: {
                  xs: "16px",
                  sm: "28px",
                  md: "20px",
                  lg: "21px",
                  xl: "24px",
                },
                fontWeight: "400",
                fontFamily: "Recoleta medium",
                color: "#000000",
                textAlign: "center",
                lineHeight: {
                  xs: "1.4",
                  sm: "1.4",
                  md: "1.4",
                  lg: "25px",
                  xl: "1.5",
                },
              }}
            >
              Providing tailored activities based on individual preferences and needs enhances cognitive function and emotional well-being for those with dementia.
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* 5th container */}


      {/* <Grid
        container
        xs={12}
        md={11}
        lg={12}
        sx={{
          display: "flex",
          padding: {
            xs: "0px",
            sm: "0px",
            md: "60px 0px 80px 88px",
            lg: "80px 88px 80px 88px",
            xl: "0px 0px 80px 88px",
          },
        }}
      >
        <Grid
          container
          item
          xs={12}
          md={5.5}
          lg={6}
          sx={{
            justifyContent: "center",
            alignItems: "center", // Fix the typo here: 'alighnItems' should be 'alignItems'
            padding: {
              xs: "0px",
              sm: "0px",
              md: "30px 30px 0px 0px",
              lg: "0px 0px 0px 0px",
              xl: "0px",
            },
          }}
        >
          <Grid item xs={10} sm={11} md={12} lg={8}>
            <Typography
              sx={{
                justifyContent: "flex-start",
                textAlign: {
                  xs: "left",  // Align left for mobile
                  sm: "left",  // Align left for small screens
                  md: "center", // Center for medium and larger screens
                  lg: "center",
                  xl: "center",
                },
                padding: {
                  xs: "30px 0px 0px 0px",
                  sm: "0px",
                  md: "0px",
                  lg: "0px 0px 0px 0px",
                  xl: "0px",
                },
                fontSize: {
                  xs: "19px",
                  sm: "24px",
                  md: "24px",
                  lg: "25px",
                  xl: "24px",
                },
                fontWeight: "400",
                fontFamily: "Recoleta medium",
                color: "#000000",
                lineHeight: {
                  xs: "1.5",
                  sm: "1.5",
                  md: "1.5",
                  lg: "27px",
                  xl: "1.5",
                },
              }}
            >
              “We founded Informed ALF because we believe that balance is key to enhancing the lives of those living with dementia. Scientific research has shown that small, thoughtful changes in daily routines and an environment designed with care can lead to significant improvements in well-being.”
            </Typography>

            <Typography
              sx={{
                justifyContent: "flex-start",
                alignItems: "center", // Fix the typo here: 'alighnItems' should be 'alignItems'
                padding: {
                  xs: "0px",
                  sm: "0px",
                  md: "0px",
                  lg: "20px 0px 0px 0px",
                  xl: "0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "24px",
                  xl: "24px",
                },
                fontWeight: "900",
                fontFamily: "Recoleta medium",
                color: "#000000",
                lineHeight: {
                  xs: "1.5",
                  sm: "1.5",
                  md: "1.5",
                  lg: "27px",
                  xl: "1.5",
                },
              }}
            >
              —
            </Typography>

            <Typography
              sx={{
                justifyContent: "flex-start",
                alignItems: "center", // Fix the typo here: 'alighnItems' should be 'alignItems'
                padding: {
                  xs: "0px",
                  sm: "0px",
                  md: "0px",
                  lg: "10px 0px 0px 0px",
                  xl: "0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "23px",
                  xl: "24px",
                },
                fontWeight: "600",
                color: "#000000",
                lineHeight: {
                  xs: "1.5",
                  sm: "1.5",
                  md: "1.5",
                  lg: "27px",
                  xl: "1.5",
                },
              }}
            >
              Nick & Ian
            </Typography>

            <Typography
              sx={{
                justifyContent: "flex-start",
                alignItems: "center", // Fix the typo here: 'alighnItems' should be 'alignItems'
                padding: {
                  xs: "0px",
                  sm: "0px",
                  md: "0px",
                  lg: "0px",
                  xl: "0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "24px",
                  xl: "24px",
                },
                fontWeight: "400",
                fontFamily: "Recoleta medium",
                color: "#000000",
                lineHeight: {
                  xs: "1.5",
                  sm: "1.5",
                  md: "1.5",
                  lg: "27px",
                  xl: "1.5",
                },
              }}
            >
              Informed ALF Founders
            </Typography>

            <Button
              sx={{

                margin: {
                  xs: "15px 0px 30px 0px",
                  sm: "0px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "5px 0px 0px 0px",
                  xl: "0px 0px 0px 0px",
                },
                width: {
                  xs: "100%",
                  sm: "auto",
                  md: "80%",
                  lg: "90%",
                  xl: "50%",
                },
                maxWidth: "550px",
                height: "50px",
                bgcolor: "#5DC0FE",
                alignItems: "center",
                color: "white",
                "&:hover": {
                  bgcolor: "#5EC0FF", // Background color on hover
                  color: "white", // Text color on hover
                },
                fontSize: { xs: "16px", sm: "18px", md: "20px", lg: "18px" },
                fontWeight: "700",
                fontFamily: "poppins",
              }}
            >
              Take a Survey
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={11} sm={12} md={6.5} lg={6}
          sx={{
            margin: {
              xs: "0px 15px 0px 15px",
              sm: "0px 0px 0px 0px",
              md: "0px 0px 0px 0px",
              lg: "0px 0px 0px 0px",
              xl: "0px 0px 0px 0px",
            }
          }}
        >
          <img
            src={cont5}
            alt="logo"
            style={{
              width: "100%",
              height: "auto",
            }}
          />
        </Grid>
      </Grid> */}








      <Grid
        container
        xs={12}
        md={11}
        lg={12}
        sx={{
          display: "flex",
          padding: {
            xs: "0px",
            sm: "50px 0px 50px 0px",
            md: "60px 0px 80px 88px",
            lg: "80px 88px 80px 88px",
            xl: "0px 0px 80px 88px",
          },
        }}
      >
        {/* Text Section */}
        <Grid
          container
          item
          xs={12}
          md={5.5}
          lg={6}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            padding: {
              xs: "0px",
              sm: "0px",
              md: "30px 0px 0px 30px",
              lg: "0px 0px 0px 0px",
              xl: "0px",
            },
            order: { xs: 2, lg: 1 }, // On small devices, text will be below image
          }}
        >
          <Grid item xs={10} sm={11} md={12} lg={8}>
            <Typography
              sx={{
                justifyContent: "flex-start",
                textAlign: {
                  xs: "left", // Align left for mobile
                  sm: "left", // Align left for small screens
                  md: "center", // Center for medium and larger screens
                  lg: "center",
                  xl: "center",
                },
                padding: {
                  xs: "30px 0px 0px 0px",
                  sm: "30px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "0px 0px 0px 0px",
                  xl: "0px",
                },
                fontSize: {
                  xs: "19px",
                  sm: "24px",
                  md: "24px",
                  lg: "25px",
                  xl: "24px",
                },
                fontWeight: "400",
                fontFamily: "Recoleta medium",
                color: "#000000",
                lineHeight: {
                  xs: "1.5",
                  sm: "1.5",
                  md: "1.5",
                  lg: "27px",
                  xl: "1.5",
                },
              }}
            >
              “We founded Informed ALF because we believe that balance is key to enhancing the lives of those living with dementia. Scientific research has shown that small, thoughtful changes in daily routines and an environment designed with care can lead to significant improvements in well-being.”
            </Typography>

            <Typography
              sx={{
                justifyContent: "flex-start",
                alignItems: "center", // Fixed typo here
                padding: {
                  xs: "0px",
                  sm: "0px",
                  md: "0px",
                  lg: "20px 0px 0px 0px",
                  xl: "0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "24px",
                  xl: "24px",
                },
                fontWeight: "900",
                fontFamily: "Recoleta medium",
                color: "#000000",
                lineHeight: {
                  xs: "1.5",
                  sm: "1.5",
                  md: "1.5",
                  lg: "27px",
                  xl: "1.5",
                },
              }}
            >
              —
            </Typography>

            <Typography
              sx={{
                justifyContent: "flex-start",
                alignItems: "center", // Fixed typo here
                padding: {
                  xs: "0px",
                  sm: "0px",
                  md: "0px",
                  lg: "10px 0px 0px 0px",
                  xl: "0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "23px",
                  xl: "24px",
                },
                fontWeight: "600",
                color: "#000000",
                lineHeight: {
                  xs: "1.5",
                  sm: "1.5",
                  md: "1.5",
                  lg: "27px",
                  xl: "1.5",
                },
              }}
            >
              Nick & Ian
            </Typography>

            <Typography
              sx={{
                justifyContent: "flex-start",
                alignItems: "center", // Fixed typo here
                padding: {
                  xs: "0px",
                  sm: "0px",
                  md: "0px",
                  lg: "0px",
                  xl: "0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "24px",
                  xl: "24px",
                },
                fontWeight: "400",
                fontFamily: "Recoleta medium",
                color: "#000000",
                lineHeight: {
                  xs: "1.5",
                  sm: "1.5",
                  md: "1.5",
                  lg: "27px",
                  xl: "1.5",
                },
              }}
            >
              Informed ALF Founders
            </Typography>

            <Button
              sx={{
                margin: {
                  xs: "15px 0px 30px 0px",
                  sm: "20px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "5px 0px 0px 0px",
                  xl: "0px 0px 0px 0px",
                },
                width: {
                  xs: "100%",
                  sm: "60%",
                  md: "80%",
                  lg: "90%",
                  xl: "50%",
                },
                maxWidth: "550px",
                height: "50px",
                bgcolor: "#5DC0FE",
                alignItems: "center",
                color: "white",
                "&:hover": {
                  bgcolor: "#5EC0FF", // Background color on hover
                  color: "white", // Text color on hover
                },
                fontSize: { xs: "16px", sm: "18px", md: "20px", lg: "18px" },
                fontWeight: "700",
                fontFamily: "poppins",
              }}
            >
              Take a Survey
            </Button>
          </Grid>
        </Grid>

        {/* Image Section */}
        <Grid
          item
          xs={11}
          sm={12}
          md={6.5}
          lg={6}
          sx={{
            margin: {
              xs: "0px 15px 0px 15px",
              sm: "0px 0px 0px 0px",
              md: "0px 0px 0px 0px",
              lg: "0px 0px 0px 0px",
              xl: "0px 0px 0px 0px",
            },
            order: { xs: 1, lg: 2 }, // On small devices, image will be above text
          }}
        >
          <img
            src={cont5}
            alt="logo"
            style={{
              width: "100%",
              height: "auto",
            }}
          />
        </Grid>
      </Grid>


      {/* 6th Container  */}

      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        sx={{
          justifyContent: "center",
          alighnItems: "center",
          bgcolor: "#FBF7E9",
          padding: {
            xs: "30px 0px 0px 0px",
            sm: "30px 0px 0px 0px",
            md: "60px 0px 0px 0px",
            lg: "80px 88px 80px 88px",
            xl: "0px 0px 0px 0px",
          },
        }}
      >
        <Grid item xs={6} md={12} lg={12}>
          <img
            src={sun}
            alt="logo"
            style={{
              width: "100%", // Make image responsive
              maxWidth: "52px",
              height: "auto",
            }}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <Typography
            sx={{
              justifyContent: "center",
              alighnItems: "center",

              padding: {
                xs: "0px 0px 20px 0px",
                sm: "0px 0px 35px 0px",
                md: "0px 0px 20px 0px",
                lg: "1px 0px 10px 0px",
                xl: "0px 0px 0px 0px",
              },
              fontSize: {
                xs: "24px",
                sm: "24px",
                md: "24px",
                lg: "48px",
                xl: "24px",
              },
              fontWeight: "600",
              fontFamily: "Recoleta medium",
              color: "#124A2C",
              //   textAlign: "left",
            }}
          >
            In the News
          </Typography>
        </Grid>

        <Grid
          item
          xs={10}
          md={12}
          lg={12}
          sx={{
            display: {
              xs: "block",
              sm: "block",
              md: "block",
              lg: "flex",
              xl: "flex",
            },
            bgcolor: "#FBF7E9",
            padding: {
              xs: "0px 0px 30px 0px",
              sm: "0px 0px 40px 0px",
              md: "0px 0px 40px 0px",
              lg: "16px 16px 16px 16px",
              xl: "0px 0px 0px 0px",
            },
          }}
        >
          {/* card 3 */}
          <Grid
            item
            xs={12}
            md={12}
            lg={4}
            sx={{
              bgcolor: "#FBF7E9",
              padding: {
                xs: "0px 0px 30px 0px",
                sm: "0px 0px 40px 0px",
                md: "0px 0px 30px 0px",
                lg: "5px 16px 16px 16px",
                xl: "0px 0px 0px 0px",
              },
            }}
          >
            <Grid item xs={12} md={12} lg={12}>
              <img
                src={thenewyorktimes}
                alt="logo"
                style={{
                  width: "100%", // Make image responsive
                  maxWidth: "359px",
                  height: "auto",
                  alighnItems: "left",
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={11.5}>
              <Typography
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",

                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "5px 0px 20px 0px",
                    lg: "4px 0px 16px 0px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "28px",
                    xl: "24px",
                  },
                  fontWeight: "400",
                  fontFamily: "Recoleta medium",
                  color: "#000000",
                  textAlign: "center",
                  lineHeight: {
                    xs: "30px ",
                    sm: "30px ",
                    md: "0px ",
                    lg: "30px ",
                    xl: "0px ",
                  },
                }}
              >
                Excessive Drinking Rose During the Pandemic. Here Are Ways to
                Cut Back.
              </Typography>
            </Grid>
            {/* </Grid> */}
          </Grid>
          {/* yellow card two */}
          <Grid
            item
            xs={12}
            md={12}
            lg={4}
            sx={{
              bgcolor: "#FBF7E9",
              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 20px 0px",
                lg: "16px 16px 16px 16px",
                xl: "0px 0px 0px 0px",
              },
            }}
          >
            <Grid item xs={12} md={12} lg={12}>
              <img
                src={usatoday}
                alt="logo"
                style={{
                  width: "100%", // Make image responsive
                  maxWidth: "359px",
                  height: "auto",
                  alighnItems: "left",
                }}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Grid item xs={12} md={12} lg={11.5}>
                <Typography
                  sx={{
                    justifyContent: "center",
                    alighnItems: "center",

                    padding: {
                      xs: "10px 0px 0px 0px",
                      sm: "8px 0px 40px 0px",
                      md: "15px 0px 20px 0px",
                      lg: "4px 0px 16px 0px",
                      xl: "0px 0px 0px 0px",
                    },
                    fontSize: {
                      xs: "24px",
                      sm: "24px",
                      md: "24px",
                      lg: "28px",
                      xl: "24px",
                    },
                    fontWeight: "400",
                    fontFamily: "Recoleta medium",
                    color: "#000000",
                    textAlign: "center",
                    lineHeight: {
                      xs: "30px ",
                      sm: "30px ",
                      md: "0px ",
                      lg: "30px ",
                      xl: "0px ",
                    },
                  }}
                >
                  Sober living for everyone is rising in popularity as Chrissy
                  Teigen, more ditch drinking.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {/*  card three */}
          <Grid
            item
            xs={12}
            md={12}
            lg={4}
            sx={{
              bgcolor: "#FBF8E9",
              padding: {
                xs: "40px 0px 30px 0px",
                sm: "0px 0px 0px 0px",
                md: "10px 0px 0px 0px",
                lg: "18px 16px 16px 16px",
                xl: "0px 0px 0px 0px",
              },
            }}
          >
            <Grid item xs={12} md={12} lg={12}>
              <img
                src={forbes}
                alt="logo"
                style={{
                  width: "100%", // Make image responsive
                  maxWidth: "180px",
                  height: "35px",
                  alighnItems: "left",
                }}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>


              <Grid item xs={12} md={12} lg={11.5}>
                <Typography
                  sx={{
                    justifyContent: "center",
                    alighnItems: "center",
                    margin: {
                      xs: "0px 0px 0px 0px",
                      sm: "0px 0px 0px 0px",
                      md: "90x 0px 0px 0px",
                      // lg: "15px 0px 16px 0px",
                      xl: "0px 0px 0px 0px",
                    },
                    padding: {
                      xs: "10px 0px 30px 0px",
                      sm: "10px 0px 30px 0px",
                      md: "90x 0px 20px 0px",
                      lg: "15px 0px 16px 0px",
                      xl: "0px 0px 0px 0px",
                    },
                    fontSize: {
                      xs: "24px",
                      sm: "24px",
                      md: "24px",
                      lg: "28px",
                      xl: "24px",
                    },
                    fontWeight: "400",
                    fontFamily: "Recoleta medium",
                    color: "#000000",
                    textAlign: "center",
                    lineHeight: {
                      xs: "30px ",
                      sm: "30px ",
                      md: "40px ",
                      lg: "30px ",
                      xl: "0px ",
                    },
                  }}
                >
                  Six Creative Takes on Dry January.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* 7th container */}

      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        sx={{
          justifyContent: "center",
          alignItems: "center", // Fixed typo: 'alighnItems' to 'alignItems'
          padding: {
            xs: "20px 0px 0px 0px",
            sm: "50px 0px 0px 0px",
            md: "40px",
            lg: "80px 88px 80px 88px",
            xl: "0px",
          },
        }}
      >
        <Grid item xs={6} md={12} lg={12}
          sx={{
            margin: {
              xs: "0px  0px 30px 0px",
              sm: "0px 0px 30px 0px",
              md: "0px 0px 15px 0px",
              lg: "0px 0px 0px 0px",
              xl: "0px 0px 0px 0px",
            }
          }}
        >
          <img
            src={sun}
            alt="logo"
            style={{
              width: "100%", // Make image responsive
              maxWidth: "52px",
              height: "auto",
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          lg={10}
          sx={{
            justifyContent: "center",
            alignItems: "center", // Fixed typo: 'alighnItems' to 'alignItems'
            margin: {
              xs: "0px  0px 50px 0px",
              sm: "0px 0px 50px 0px",
              md: "0px 0px 40px 0px",
              lg: "0px 0px 0px 0px",
              xl: "0px 0px 0px 0px",
            },
            padding: {
              xs: "0px",
              sm: "0px",
              md: "0px",
              lg: "8px 0px 0px 0px",
              xl: "0px",
            },
            fontSize: {
              xs: "24px",
              sm: "24px",
              md: "24px",
              lg: "48px",
              xl: "24px",
            },
            fontWeight: "600",
            fontFamily: "Recoleta medium",
            color: "#124A2C",
            textAlign: "center",
            lineHeight: {
              xs: "0px",
              sm: "0px",
              md: "0px",
              lg: "45px",
              xl: "0px",
            },
          }}
        >
          From the Blog
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{

            padding: {
              xs: "0px",
              sm: "0px",
              md: "0px",
              lg: "64px 0px 0px 0px",
              xl: "0px",
            },
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap", // Allow items to wrap in smaller screens
          }}
        >
          {[
            {
              image: daydrink,
              title: "10 Tips to Day Drink Mindfully This Summer",
            },
            {
              image: "https://images.prismic.io/sunnysidetest/b3989793-6dda-42cc-b5d5-d83d0b71c91c_43ddd3e7-e520-44dc-b52e-d6a880b9fb48+%285%29.png?auto=compress,format?w=1920&q=100",
              title: "How to Stay Hydrated in Hot Weather",
            },
            {
              image: "https://images.prismic.io/sunnysidetest/c95b1aa8-f258-4f6e-9fd8-b30ccb416eb4_30+alcohol+challenge.png?auto=compress,format?w=1920&q=100",
              title: "The Best Summer Cocktails to Try",
            },
            {
              image: "https://images.prismic.io/sunnysidetest/fd3213ac-123e-4faa-9415-77d559b9df7f_43ddd3e7-e520-44dc-b52e-d6a880b9fb48+%289%29.png?auto=compress,format?w=1920&q=100",
              title: "Summer BBQ Essentials for Your Next Party",
            },
            {
              image: "https://images.prismic.io/sunnysidetest/85ffa1e3-ace3-4bc4-8a21-a398f3e1cc84_43ddd3e7-e520-44dc-b52e-d6a880b9fb48+%287%29.png?auto=compress,format?w=3840&q=100",
              title: "Top 5 Outdoor Activities for the Family",
            },
          ].map((card, index) => (
            <Grid
              item
              xs={11}
              sm={4}
              md={4}
              lg={2.4}
              sx={{
                padding: {
                  xs: "0px",
                  sm: "10px",
                  md: "10px",
                  lg: "0px 20px 0px 0px",
                  xl: "0px",
                },
                textAlign: "center", // Ensures the text aligns center
              }}
              key={index}
            >
              <Grid item>
                <img
                  src={card.image}
                  alt={`logo-${index}`}
                  style={{
                    width: "100%", // Make image responsive
                    maxWidth: "202px",
                    height: "auto",
                    padding: "0px 0px 0px 0px",
                  }}
                />
              </Grid>
              <Typography
                sx={{
                  margin: {
                    xs: "0px 0px 30px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "0px 0px 0px 0px",
                    xl: "0px 0px 0px 0px",
                  },
                  padding: "20px 0",
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "28px",
                    xl: "24px",
                  },
                  fontWeight: "600",
                  fontFamily: "Recoleta medium",
                  color: "#124a2c",
                  textAlign: "center", // Ensure text is aligned properly on smaller screens
                  lineHeight: {
                    xs: "30px",
                    sm: "30px",
                    md: "40px",
                    lg: "35px",
                    xl: "0px",
                  },
                }}
              >
                {card.title}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>



      {/* 8th container */}
      {/* 8th container */}
      {/* 8th container */}
      {/* 8th container */}

      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          bgcolor: "#FBF7E9",
          padding: {
            xs: "30px 0px 30px 0px",
            sm: "30px 0px 30px 0px",
            md: "70px 78px 70px 78px",
            lg: "80px 88px 80px 88px",
            xl: "80px 88px 80px 88px",
          },
        }}
      >
        <Grid item xs={6} md={12} lg={12}>
          <img
            src={sun} // Use your actual image path here
            alt="logo"
            style={{
              width: "100%",
              maxWidth: "52px",
              height: "auto",
            }}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <Typography
            sx={{
              justifyContent: "center",
              alignItems: "center",
              padding: {
                xs: "20px 0px 30px 0px",
                sm: "10px  0px 35px 0px",
                md: "0px 0px 0px 0px",
                lg: "1px 0px 10px 0px",
                xl: "0px 0px 0px 0px",
              },
              fontSize: { xs: "24px", sm: "24px", md: "24px", lg: "48px", xl: "24px" },
              fontWeight: "600",
              fontFamily: "Recoleta medium",
              color: "#124A2C",
            }}
          >
            Meet Our Team
          </Typography>
        </Grid>

        {/* Loop through teamMembers array to render each card */}
        {teamMembers.map((member, index) => (
          <Grid
            key={index}
            item
            xs={12}
            sm={5}
            md={4}  // Two cards per row on medium screens
            lg={4}  // Three cards per row on large screens
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: {
                xs: "0px",
                sm: "0px",
                md: "30px",
                lg: "16px",
                xl: "0px",
              },
            }}
          >
            <Grid
              item
              xs={12}

              md={12}
              lg={12}
              sx={{
                bgcolor: "#FBF7E9",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: {
                  xs: "0px 0px 65px 0px",
                  sm: "0px 0px 35px 0px",
                  md: "0px 0px 20px 0px",
                  lg: "16px 0px 0px 0px",
                  xl: "0px 0px 0px 0px",
                },
              }}
            >
              <img
                src={member.image}
                alt={member.name}
                style={{
                  width: "100%",
                  maxWidth: "200px",
                  height: "200px",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
              />
              <Typography
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  padding: {
                    xs: "35px 0px 0px 0px",
                    sm: "35px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 1px 0px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: { xs: "24px", sm: "24px", md: "24px", lg: "24px", xl: "24px" },
                  fontWeight: "600",
                  fontFamily: "Recoleta medium",
                  color: "#124A2C",
                  textAlign: "center",
                  lineHeight: { xs: "0px", sm: "0px", md: "50px", lg: "30px", xl: "0px" },
                }}
              >
                {member.name}
              </Typography>
              <Typography
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  padding: {
                    xs: "20px 0px 0px 0px",
                    sm: "20px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 1px 0px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: { xs: "24px", sm: "24px", md: "24px", lg: "24px", xl: "24px" },
                  fontWeight: "400",
                  fontFamily: "Recoleta medium",
                  color: "#124A2C",
                  textAlign: "center",
                  lineHeight: { xs: "25px", sm: "30px", md: "30px", lg: "30px", xl: "0px" },
                }}
              >
                {member.position}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>


      {/* 9th container */}
      {/* 9th container */}
      {/* 9th container */}
      {/* 9th container */}


      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        sx={{
          justifyContent: "center",
          alignItems: "center", // Corrected typo 'alighnItems' to 'alignItems'
          bgcolor: "#ffffff",
          margin: {
            xs: "40px 0px 0px 0px",
            sm: "0px 0px 0px 0px",
            md: "40px 0px 0px 0px",
            lg: "0px 0px 0px 0px",
            xl: "0px 0px 0px 0px",
          },
          padding: {
            xs: "0px",
            sm: "0px",
            md: "0px",
            lg: "80px 88px 80px 88px",
            xl: "0px",
          },
        }}
      >
        <Grid item xs={12} md={12} lg={12}>
          <Typography
            sx={{
              justifyContent: "center",
              alignItems: "center", // Corrected typo
              padding: {
                xs: "40px 0px 40px 0px",
                sm: "40px 0px 40px 0px",
                md: "0px 0px 0px 0px",
                lg: "1px 0px 10px 0px",
                xl: "0px 0px 0px 0px",
              },
              fontSize: { xs: "24px", sm: "44px", md: "44px", lg: "48px", xl: "24px" },
              fontWeight: "400",
              fontFamily: "Recoleta medium",
              color: "#124A2C",
            }}
          >
            Advisors
          </Typography>
        </Grid>

        {/* Loop for 10 cards */}
        {[...Array(10)].map((_, index) => (
  <Grid
    key={index}
    item
    xs={12}  // Use full width on small devices
    sm={6}   // Two cards per row on small devices
    md={4}   // Two cards per row on medium devices
    lg={4}   // Three cards per row on large devices
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: {
        xs: "0px 0px 20px 0px",  // Add spacing between cards on small devices
        sm: "0px 0px 30px 0px",  // Increase spacing on small screens
        md: "0px 0px 30px 0px",  // Adjust spacing on medium screens
        lg: "0px 0px 30px 0px",  // Keep consistent spacing on large screens
        xl: "0px 0px 30px 0px",
      },
      padding: {
        xs: "10px",  // Add some padding on small devices
        sm: "10px",
        md: "10px",
        lg: "16px",
        xl: "16px",
      },
    }}
  >
    <Grid
      item
      xs={12}
      md={12}
      lg={12}
      sx={{
        bgcolor: "#ffffff",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: {
          xs: "20px",  // Add padding on small devices for better spacing
          sm: "20px",
          md: "10px",
          lg: "16px",
          xl: "16px",
        },
        borderRadius: "8px",  // Rounded corners for better aesthetics
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",  // Add shadow for better card appearance
      }}
    >
      <img
        src="https://images.prismic.io/sunnysidetest/600cfe76-ebc4-41f6-9671-7154444f1d04_headshot-hrishi2.jpg?auto=compress,format?w=640&q=100"
        alt="advisor"
        style={{
          width: "100%",
          maxWidth: "200px",  // Ensure image doesn't exceed its container width
          height: "200px",
          borderRadius: "50%",  // Circular image
          objectFit: "cover",  // Ensure image scales properly
          marginBottom: "20px",  // Add space between image and text
        }}
      />
      <Typography
        sx={{
          margin: "0px",
          fontSize: { xs: "20px", sm: "24px", md: "24px", lg: "24px", xl: "24px" },
          fontWeight: "600",
          fontFamily: "Recoleta medium",
          color: "#124A2C",
          textAlign: "center",
          lineHeight: "30px",
        }}
      >
        Nick Allen
      </Typography>
    </Grid>
  </Grid>
))}

      </Grid>



      {/* 10th container */}
      {/* 10th container */}
      {/* 10th container */}
      {/* 10th container */}
      {/* 10th container */}


      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        sx={{
          bgcolor: "#ffe635",
          display: "flex",
          // flexDirection: { xs: "column", md: "row" }, // Stack on small screens, row on larger ones
          padding: {
            xs: "0px",
            sm: "0px",
            md: "0px 0px 0px 88px",
            lg: "0px 88px 0px 88px",
            xl: "0px 0px 0px 0px",
          },
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6.5}
          sx={{
            padding: {
              xs: "0px",
              sm: "0px",
              md: "0px 0px 0px 0px",
              lg: "0px 0px 0px 0px",
              xl: "0px 0px 0px 0px",
            },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <img
            src={mobile}
            alt="logo"
            style={{
              width: "100%", // Make image responsive
              maxWidth: "478px", // Control max width
              height: "auto", // Maintain aspect ratio
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={5.5}
          sx={{
            padding: {
              xs: "0px",
              sm: "0px",
              md: "180px 0px 80px 0px",
              lg: "91px 0px 80px 0px",
              xl: "0px 0px 0px 0px",
            },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: {
              xs: "center",
              sm: "center",
              md: "center",
              lg: "left",
              xl: "left",
            }, // Align left on larger screens
            height: "100%",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "24px", sm: "30px", md: "44px", lg: "48px", xl: "24px" },
              fontWeight: "600",
              fontFamily: "Recoleta medium",
              color: "#124A2C",
              textAlign: {
                xs: "center",
                sm: "center",
                md: "flex-start",
                lg: "left",
                xl: "left",
              },
              lineHeight: { xs: "28px", sm: "32px", md: "48px", lg: "54px", xl: "28px" },
              padding: {
                xs: "30px 30px 0px 30px",
                sm: "150px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "0px 0px 0px 0px",
                xl: "0px 0px 0px 0px",
              },

            }}
          >
            Help us improve dementia care by taking our survey.
          </Typography>
          <Button
            sx={{
              justifyContent: "center",
              margin: {
                xs: "20px 0px 30px 0px",
                sm: "20px 0px 0px 0px",
                md: "20px 0px 0px 0px",
                lg: "20px 0px 0px 0px",
                xl: "20px 0px 0px 0px",
              },
              width: { xs: "70%", sm: "60%", md: "80%", lg: "69%", xl: "50%" },
              maxWidth: "550px",
              height: "55px",
              bgcolor: "#5DC0FE",
              color: "white",
              "&:hover": {
                bgcolor: "#5EC0FF",
                color: "white",
              },
              fontSize: { xs: "16px", sm: "18px", md: "20px", lg: "18px" },
              fontWeight: "700",
              fontFamily: "poppins",
            }}
          >
            Take a Survey
          </Button>
        </Grid>
      </Grid>











      {/* <Grid
        container
        xs={12}
        md={12}
        lg={12}
        sx={{
          bgcolor: "#ffe635",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: { xs: "column", sm: "column", md: "row" }, // Stack on small screens, row on larger ones
          padding: {
            xs: "0px",
            sm: "0px",
            md: "0px 0px 0px 0px",
            lg: "0px 88px 0px 88px",
            xl: "0px 0px 0px 0px",
          },
        }}
      >
        <Grid
          item
          xs={12}
          md={5}
          lg={5.5}
          sx={{
            padding: {
              xs: "0px",
              sm: "0px",
              md: "0px",
              lg: "91px 0px 80px 0px",
              xl: "0px 0px 0px 0px",
            },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: {
              xs: "center",
              sm: "center",
              md: "center",
              lg: "left",
              xl: "left",
            },
            height: "100%",
            order: { xs: 1, sm: 1, md: 0 }, // Text container appears first only on small screens
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "24px", sm: "24px", md: "24px", lg: "48px", xl: "24px" },
              fontWeight: "600",
              fontFamily: "Recoleta medium",
              color: "#124A2C",
              textAlign: {
                xs: "center",
                sm: "center",
                md: "flex-start",
                lg: "left",
                xl: "left",
              },
              lineHeight: { xs: "28px", sm: "28px", md: "28px", lg: "54px", xl: "28px" },
              padding: {
                xs: "30px 30px 0px 30px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "0px 0px 0px 0px",
                xl: "0px 0px 0px 0px",
              },
            }}
          >
            Help us improve dementia care by taking our survey.
          </Typography>
          <Button
            sx={{
              justifyContent: "center",
              margin: {
                xs: "20px 0px 30px 0px",
                sm: "20px 0px 0px 0px",
                md: "20px 0px 0px 0px",
                lg: "20px 0px 0px 0px",
                xl: "20px 0px 0px 0px",
              },
              width: { xs: "70%", sm: "auto", md: "80%", lg: "69%", xl: "50%" },
              maxWidth: "550px",
              height: "55px",
              bgcolor: "#5DC0FE",
              color: "white",
              "&:hover": {
                bgcolor: "#5EC0FF",
                color: "white",
              },
              fontSize: { xs: "16px", sm: "18px", md: "20px", lg: "18px" },
              fontWeight: "700",
              fontFamily: "poppins",
            }}
          >
            Take a Survey
          </Button>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={6.5}
          sx={{
            padding: {
              xs: "0px",
              sm: "0px",
              md: "0px 0px 0px 0px",
              lg: "0px 0px 0px 0px",
              xl: "0px 0px 0px 0px",
            },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            order: { xs: 2, sm: 2, md: 1 }, // Image container appears second only on small screens
          }}
        >
          <img
            src={mobile}
            alt="logo"
            style={{
              width: "100%", // Make image responsive
              maxWidth: "478px", // Control max width
              height: "auto", // Maintain aspect ratio
            }}
          />
        </Grid>
      </Grid> */}



      {/* footer */}
      <Grid
        container
        sx={{
          bgcolor: "white",
          margin: "0px",
          padding: {
            xs: "20px 15px",
            sm: "30px 20px",
            md: "50px 27px",
            lg: "50px 27px 20px 27px",
          },
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            bgcolor: "white",
            margin: "0px",
            padding: {
              xs: "20px 15px",
              sm: "30px 20px",
              md: "40px 50px 80px 50px",
              lg: "0px 0px 20px 50px",
            },
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              bgcolor: "white",
              color: "green",
              margin: "0px 0px 40px 0px",
              height: "60px",
              width: "290px",
              display: "flex",
              textAlign: "left",
            }}
          >
            <img
              src={image}
              alt="logo"
              style={{ width: "70px", height: "60px" }}
            />
            <Typography
              sx={{
                fontSize: { xs: "24px", md: "30px" },
                fontWeight: "bold",
                color: "#125782",
                padding: "10px 0px 0px 5px",
              }}
            >
              Informed ALF
            </Typography>
          </Grid>

          <Grid
            container
            spacing={4}
            sx={{
              bgcolor: "white",
              color: "black",
              padding: "0px",
              textAlign: "left",
            }}
          >
            <Grid
              item
              xs={6}
              sm={6}
              md={3}
              lg={2}
              sx={{
                bgcolor: "white",
                margin: { xs: "0px 0px", md: "0px 100px 0px 0px" },
              }}
            >
              <Link
                href="/about"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "12px",
                  height: "32px",
                }}
              >
                Explore
              </Link>
              <Divider style={{ margin: "16px 0px 20px 0px" }} />
              <Link
                href="/"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "12px",
                  height: "32px",
                }}
              >
                Home
              </Link>
              {/* <br /> */}
              {/* <Link
                // href="#"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "12px",
                  height: "32px",
                }}
              >
                Blog
              </Link> */}
              {/* <br /> */}
              {/* <Link
                // href="#"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "12px",
                  height: "32px",
                }}
              >
                Reviews
              </Link> */}
              <br />
              <Link
                href="/about"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "12px",
                  height: "32px",
                }}
              >
                About
              </Link>
              <br />
              <Link
                href="/Help"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "12px",
                  height: "32px",
                }}
              >
                Help & Support
              </Link>
            </Grid>

            <Grid
              item
              xs={6}
              sm={6}
              md={3}
              lg={2}
              sx={{
                bgcolor: "white",
                margin: { xs: "0px", md: "0px 100px 0px 0px" },
                textAlign: "left",
              }}
            >
              <Link
                href="#"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                Get in Touch
              </Link>
              <Divider style={{ margin: "16px 0px 20px 0px" }} />
              <Link
                href="https://www.instagram.com/informedalf?igsh=MWpuYXFvYmd6YW5zZg=="
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "12px",
                }}
              >
                Instagram
              </Link>
              <br />
              <Link
                href="https://x.com/InformedAlf"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "12px",
                }}
              >
                X.com
              </Link>
              <br />
              <Link
                href="https://www.youtube.com/channel/UCsB14X9mnpALoHPE6JqxSwA"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "12px",
                }}
              >
                Youtube
              </Link>
              <br />
              <Link
                href="https://www.facebook.com/profile.php?id=61568607891886&sk=about"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "12px",
                }}
              >
                Facebook
              </Link>
              <br />
              <Link
                href="/contact"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "12px",
                }}
              >
                Contact Us
              </Link>
              <br />
              
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              sx={{
                bgcolor: "white",
                color: "black",
                margin: "0px",
                textAlign: "left",
                padding: { xs: "0px 0px 0px 0px", md: "0px 0px 0px 0px" },
              }}
            >
              <Link
                href="#"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "13px",
                }}
              >
                About Informed ALF
              </Link>
              <Divider style={{ margin: "16px 0px 20px 0px" }} />
              <Typography style={{ color: "black", fontSize: "13px" }}>
                Informed ALF was created to provide ease of access to important
                information about a person with cognitive decline whether they
                live inside a community or continue to live on their own.
              </Typography>
              <br />
              <Typography style={{ color: "black", fontSize: "13px" }}>
                Informed ALF safeguards the person's preferences, legal
                paperwork, medical, emotional, and physical history,
                personalized tips and techniques for informed personal care, and
                so much more. This app will revolutionize care for those unable
                to recall in everyday or emergency situations.
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              bgcolor: "white",
              color: "black",
              margin: "40px 0px 40px 0px",
              textAlign: "left",
            }}
          >
            <Divider sx={{ margin: "50px 0px 20px 0px", color: "black" }} />

            <Grid
              container
              sx={{
                bgcolor: "white",
                display: "flex",
                margin: "30px 0px 0px 0px",
              }}
            >
              <Grid
                item
                xs={12}
                lg={4}
                sx={{ bgcolor: "white", display: "flex", alignItems: "center" }}
              >
                <Typography
                  sx={{
                    color: "#919191",
                    fontSize: { xs: "12px", md: "14px" },
                    margin: "0px",
                  }}
                >
                  Copyright 2024 Informed ALF. All rights reserved.
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                lg={8}
                sx={{
                  bgcolor: "white",
                  display: "flex",
                  justifyContent: { xs: "center", lg: "right" },
                  fontSize: { xs: "12px", md: "14px" },
                  margin: "0px",
                }}
                style={{ color: "#919191" }}
              >
                <Link
                  href="/policy"
                  style={{ color: "#919191", marginRight: "10px" }}
                >
                  Privacy Policy
                </Link>
                |
                <Link href="/Terms" style={{ color: "#919191", margin: "0px 10px" }}>
                  Terms & Conditions
                </Link>
                |
                <Link onClick={handleOpenDialog} style={{ color: "#919191", marginLeft: "10px", cursor: "pointer" }}>
                  Cookie Policy
                </Link>

                {/* Cookie Policy Dialog */}
                <Dialog
                  open={openDialog}
                  onClose={handleCloseDialog}
                  maxWidth="sm"
                  fullWidth
                  sx={{
                    "& .MuiPaper-root": {
                      borderRadius: "20px",
                    },
                  }}
                >
                  <DialogTitle>Cookie Policy</DialogTitle>
                  <Divider />

                  <DialogContent sx={{ minHeight: "350px" }}>
                    <Typography variant="body1" color="text.secondary" gutterBottom>
                      This website uses cookies to enhance user experience, personalize content,
                      and analyze our traffic. By using our site, you agree to our use of cookies.
                    </Typography>
                    <Typography variant="body1" color="text.secondary" gutterBottom>
                      Cookies are small data files stored on your device. They help us improve
                      our services and provide a more personalized experience. You may disable cookies
                      through your browser settings, but this may affect your experience on our site.
                    </Typography>

                    <Typography variant="body1" color="text.secondary" gutterBottom>
                      At Informed ALF, we understand the importance of privacy and safeguarding sensitive
                      information, especially for individuals with dementia and their caregivers. Our cookie policy
                      is designed to provide transparency about the data we collect and how we use it to enhance the user experience.
                    </Typography>

                    <Typography variant="body1" color="text.secondary" gutterBottom>
                      We use cookies to track user interactions on our site, which allows us to offer a more tailored experience for dementia patients,
                      their families, and caregivers. For instance, cookies help us remember your preferences for easy navigation, and they enable
                      the personalization of health-related content based on your needs. Some cookies are essential for the functionality of our website, such as
                      those that enable you to access secured areas or submit forms.
                    </Typography>

                    <Typography variant="body1" color="text.secondary" gutterBottom>
                      Additionally, we use cookies to collect analytics data to understand how visitors use our website. This helps us improve
                      the accessibility of information and resources for dementia patients, caregivers, and healthcare professionals.
                      If you have concerns about the use of cookies on our website, you may manage or disable cookies through your browser settings.
                      Please note that disabling certain cookies may limit your ability to use some features on our site.
                    </Typography>

                    <Typography variant="body1" color="text.secondary" gutterBottom>
                      By continuing to use our website, you consent to our use of cookies as outlined in this policy. We may update our cookie policy from
                      time to time, so we encourage you to review it periodically to stay informed.
                    </Typography>
                  </DialogContent>

                  {/* Button for agreeing to cookies */}
                  <DialogActions sx={{ justifyContent: "center", margin: "0px 50px 0px 50px" }}>
                    <Button
                      color="success"
                      sx={{
                        margin: "0px 10px",
                        borderRadius: "10px",
                        width: "50%", // Adjust width for balance
                      }}
                      variant="contained"
                      onClick={handleCloseDialog}
                    >
                      Agree
                    </Button>

                    {/* Button for rejecting cookies */}
                    <Button
                      color="error"
                      sx={{

                        margin: "0px 10px",
                        borderRadius: "10px",
                        width: "50%", // Adjust width for balance
                      }}
                      variant="contained"
                      onClick={handleCloseDialog}
                    >
                      Reject
                    </Button>
                  </DialogActions>

                  <DialogActions sx={{ justifyContent: "center" }}>
                    <Button
                      sx={{
                        backgroundColor: "#b31c31",
                        "&&:hover": {
                          backgroundColor: "#b31c31",
                        },
                        width: {
                          xs: "100%", // Full width on extra-small screens (mobile)
                          sm: "80%",  // 80% width on small screens and above
                          md: "80%",  // 60% width on medium screens and above
                        },
                        margin: {
                          xs: "0px 0px 20px 0px", // Margin for extra-small screens
                          sm: "0px 0px 15px 0px", // Adjusted margin for small screens and above
                          md: "0px 0px 15px 0px", // Adjusted margin for medium screens and above
                        },
                        borderRadius: "10px",
                      }}
                      variant="contained"
                      onClick={handleCloseDialog}
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>



              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default About;
