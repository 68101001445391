import React from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Button, Divider, Stack } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import { Link } from 'react-router-dom';


const Help = () => {
  return (
    <Box sx={{ minHeight: '100vh', bgcolor: 'linear-gradient(180deg, #FBF7E9 0%, #ECE4D8 100%)', color: '#B31C31', display: 'flex', flexDirection: 'column', alignItems: 'center', py: 5 }}>
      
      {/* Page Title */}
      <Box textAlign="center" mb={6} sx={{ width: '90%', maxWidth: '800px' }}>
        <Typography variant="h4" gutterBottom sx={{ color: '#125782', fontWeight: 'bold' }}>
          Help & Support
        </Typography>
        <Typography variant="body1" sx={{ color: '#B31C31', maxWidth: '600px', mx: 'auto', fontSize: '1.1rem' }}>
          Our dedicated support team is here to assist with any queries. Check out our frequently asked questions or get in touch with us directly for personalized help.
        </Typography>
      </Box>

      {/* Support Contact Information */}
      <Box textAlign="center" mb={5} sx={{ bgcolor: '#125782', p: 4, borderRadius: 3, width: '90%', maxWidth: '800px', boxShadow: 3, transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.02)' } }}>
        <Stack alignItems="center" spacing={1}>
          <SupportAgentIcon sx={{ color: '#FBF7E9', fontSize: 48 }} />
          <Typography variant="h6" gutterBottom sx={{ color: '#FBF7E9' }}>
            Contact Support
          </Typography>
        </Stack>
        <Typography variant="body2" sx={{ color: '#FBF7E9', mb: 2 }}>
          <PhoneIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> (123) 456-7890 <br />
          <EmailIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> support@informedalf.com
        </Typography>
        <Link 
         to='https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox?compose=support@informedalf.com'
        >
        <Button 
          variant="contained" 
          color="secondary" 
          sx={{ mt: 2, bgcolor: '#B31C31', color: '#FBF7E9', '&:hover': { bgcolor: '#920026' }, boxShadow: 2 }}
        >
          Email Support
        </Button>
        </Link>
      </Box>

      <Divider sx={{ my: 4, bgcolor: '#B31C31', width: '90%', maxWidth: '800px' }} />

      {/* Frequently Asked Questions (FAQs) */}
      <Box mb={5} sx={{ width: '90%', maxWidth: '800px' }}>
        <Typography variant="h6" gutterBottom sx={{ color: '#125782', textAlign: 'center', fontWeight: 'bold' }}>
          Frequently Asked Questions
        </Typography>

        {[
          {
            question: "How do I register a patient?",
            answer: "To register a patient, navigate to the 'Register' page and fill out the form with all required details. You will receive a confirmation email with additional instructions after successful registration."
          },
          {
            question: "How can I access the caregiver's care plan?",
            answer: "Once logged in, go to the 'Care Plan' section to view and update the caregiver's activities and schedule. Each care plan is tailored to individual needs, so be sure to check regularly for updates."
          },
          {
            question: "What services does this facility provide?",
            answer: "We provide specialized care for dementia patients, including memory support, behavioral management, 24/7 medical care, and assistance with daily activities. Our team is trained to handle various stages of dementia with compassion."
          },
          {
            question: "Can I customize the care plan?",
            answer: "Yes, you can work with our caregivers to adjust the care plan based on evolving needs. Contact our support team for more details."
          },
          {
            question: "How do I know if my loved one needs specialized dementia care?",
            answer: "If your loved one shows signs of memory loss, confusion, or behavioral changes that affect their daily life, it may be beneficial to consult with a dementia care professional. Our facility offers consultations to help determine the best care plan."
          },
          {
            question: "What activities are available for patients?",
            answer: "We offer a variety of engaging activities, including music therapy, art sessions, memory exercises, and physical activities tailored to different stages of dementia. These activities are designed to stimulate cognitive function and enhance quality of life."
          },
          {
            question: "How can I update my contact information?",
            answer: "You can update your contact information by navigating to the 'Profile' section on your account page. Make sure to save the changes once updated."
          },
          {
            question: "What safety measures are in place?",
            answer: "Our facility has 24/7 surveillance, secured entry, and a team of trained professionals available at all times. We adhere to strict health and safety guidelines to ensure a safe environment for all residents."
          },
          {
            question: "Is there any family support program available?",
            answer: "Yes, we offer support programs for families to help them cope with the challenges of caring for a loved one with dementia. Contact our support team for more information on family counseling sessions and support groups."
          },
        ].map((faq, index) => (
          <Accordion key={index} sx={{ mb: 2, bgcolor: '#FBF7E9', color: '#125782', boxShadow: 1, '& .Mui-expanded': { bgcolor: '#EDE7D9' } }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#B31C31' }} />}>
              <Box display="flex" alignItems="center">
                <HelpOutlineIcon sx={{ color: '#125782', mr: 1 }} />
                <Typography variant="subtitle1" sx={{ color: '#125782', fontWeight: 'bold' }}>{faq.question}</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2" sx={{ color: '#B31C31' }}>
                {faq.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>

      {/* Get Additional Help */}
      <Box textAlign="center" mt={5} sx={{ bgcolor: '#125782', p: 4, borderRadius: 3, width: '90%', maxWidth: '800px', boxShadow: 3, transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.02)' } }}>
        <Typography variant="h6" gutterBottom sx={{ color: '#FBF7E9', fontWeight: 'bold' }}>
          Need More Assistance?
        </Typography>
        <Typography variant="body2" sx={{ color: '#FBF7E9', mb: 2, maxWidth: '600px', mx: 'auto', fontSize: '1.1rem' }}>
          Our team is available to assist you at any time. If you have additional questions or require further help, please reach out to us.
        </Typography>
        <Link to="/contact">
        <Button 
          variant="contained" 
          color="secondary" 
          sx={{ mt: 2, bgcolor: '#B31C31', color: '#FBF7E9', '&:hover': { bgcolor: '#920026' }, boxShadow: 2 }}
        >
          Contact Us
        </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default Help;









